import {
  BEFORE_LOGIN,
  REGISTER_ACTION,
  GET_ERRORS,
  LOGIN_ACTION,
  SET_CUSTOMER_PASSWORD,
  CART_RETURNED,
} from "../../redux/types";
import { ENV } from "../../config/config";
import swal from "sweetalert";
import { handleResponse } from "../../util/helper";
import { getHttpRequest, postHttpRequest } from "../../asset/axios";

export const beforeLogin = () => {
  return {
    type: BEFORE_LOGIN,
  };
};
export const registerAction = (payload) => (dispatch) => {
  postHttpRequest(`${ENV.url}auth/register`, JSON.stringify(payload))
    .then(handleResponse)
    .then((data) => {
      if (data.status) {
        dispatch({
          type: REGISTER_ACTION,
          payload: data.message,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};

export const loginAction = (payload) => (dispatch) => {
  postHttpRequest(`${ENV.url}auth/login`, JSON.stringify(payload))
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        localStorage.setItem("accessToken", data.data.accessToken);
        localStorage.setItem("refreshToken", data.data.refreshToken);
        ENV.encryptUserData(data.data);
        dispatch({
          type: LOGIN_ACTION,
          payload: data,
        });
      } else {
        swal({
          text: data.message,
          icon: "warning",
          button: "OK!",
        });
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};
export const loginViaAdminAction = (payload) => (dispatch) => {
  postHttpRequest(`${ENV.url}auth/login-via-admin`, JSON.stringify(payload))
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        localStorage.setItem("accessToken", data.data.accessToken);
        localStorage.setItem("refreshToken", data.data.refreshToken);
        ENV.encryptUserData(data.data);
        dispatch({
          type: LOGIN_ACTION,
          payload: data,
        });
      } else {
        swal({
          text: data.message,
          icon: "warning",
          button: "OK!",
        });
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};

export const verifyEmail = (userId) => (dispatch) => {
  const url = `${ENV.url}auth/verify-email/${userId}`;
  getHttpRequest(url, "", {}, true)
    .then(handleResponse)
    .then((data) => {
      if (data.success && data.data.isPasswordEmpty) {
        swal({
          text: data.message,
          icon: "success",
          button: "OK",
        });
        dispatch({
          type: SET_CUSTOMER_PASSWORD,
          payload: data.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const setPasswordAction = (payload) => (dispatch) => {
  postHttpRequest(`${ENV.url}auth/set-password`, JSON.stringify(payload))
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        swal({
          text: data.message,
          icon: "success",
          button: "OK",
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};

export const forgetPassword = (payload) => (dispatch) => {
  postHttpRequest(`${ENV.url}auth/forgot-password`, JSON.stringify(payload))
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        swal({
          text: data.message,
          icon: "success",
          button: "OK",
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};

export const resetPassword = (payload) => (dispatch) => {
  postHttpRequest(`${ENV.url}auth/reset-password`, JSON.stringify(payload))
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        swal({
          text: data.message,
          icon: "success",
          button: "OK",
        });
      } else {
        swal({
          text: data.message,
          icon: "error",
        });
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      swal({
        text: errors,
        icon: "error",
      });
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};

export const getUserCart = (userId) => (dispatch) => {
  getHttpRequest(`${ENV.url}abandoned-cart/get-user-cart/${userId}`)
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        dispatch({
          type: CART_RETURNED,
          payload: data.abandonedCart,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};
