import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import LocalizedStrings from "react-localization";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { beforeBanner, getBanners } from "../banner/banner.action";
import localizations from "../../localization";
import { getMenu } from "../cms/cms.action";
import { useLanguage } from "../../context/language";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeSlider.css";

const HomeSlider = (props) => {
  // const url = require("url");
  const { lang } = useLanguage();
  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);
  const [sliderSpeed, setSliderSpeed] = useState(1000);
  const [banners, setBanners] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: sliderSpeed,
    adaptiveHeight: true,
  };

  useEffect(() => {
    setSliderSpeed(
      props.siteSetting.settings?.sliderSpeed
        ? props.siteSetting.settings?.sliderSpeed * 1000
        : 1000,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.siteSetting.settings]);

  useEffect(() => {
    props.beforeBanner();
    props.getBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.banner.getBannerAuth) {
      setBanners(props.banner.banners);
    }
    props.beforeBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.banner.getBannerAuth]);

  return (
    <>
      {banners.length > 0 ? (
        <Slider {...settings} className="home-banner-slider">
          {banners.map((banner, index) => {
            banner.description = banner.description.replace(/\s\s+/g, " ");
            banner.description = banner.description.replace(/&nbsp;/g, " ");
            banner.descriptionDE = banner.descriptionDE.replace(/\s\s+/g, " ");
            banner.descriptionDE = banner.descriptionDE.replace(/&nbsp;/g, " ");
            banner.title = banner.title.replace(/\s\s+/g, " ");
            banner.title = banner.title.replace(/&nbsp;/g, " ");
            banner.titleDE = banner.titleDE.replace(/\s\s+/g, " ");
            banner.titleDE = banner.titleDE.replace(/&nbsp;/g, " ");
            {
              /* let ctaLink = url.parse(banner.CTAlink, true); */
            }
            let ctaLink = banner.CTAlink;
            let finalLink = "";
            ctaLink = ctaLink.host;

            finalLink = `${banner.CTAlink}/${lang}`;

            return (
              <div
                className="home-banner custom-container"
                style={{ background: banner.color }}
                key={index}
              >
                <Container fluid style={{ background: banner.color }}>
                  <Row className="align-items-center">
                    <Col lg="6" md="12" xs="12">
                      <div className="customBan banner-text">
                        <div>
                          <h1 style={{ color: banner.fontColor }}>
                            {lang === "de" ? banner.titleDE : banner.title}
                          </h1>
                          <p
                            style={{ color: banner.fontColor }}
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === "de"
                                  ? banner.descriptionDE
                                  : banner.description,
                            }}
                          ></p>
                          <hr></hr>
                          {banner.CTAlink ? (
                            <a
                              href={finalLink}
                              target="_blank"
                              className="my-account-login"
                            >
                              <button
                                type="button"
                                className="account-text-btn"
                              >
                                {lang === "de"
                                  ? banner.CTAtextDE
                                  : banner.CTAtext}{" "}
                              </button>
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg="6" md="12" xs="12" className="mb-lg-0 mb-4 px-0">
                      {banner.type === "video" && banner.url && (
                        <div className="banner-img p-0 m-2">
                          <ReactPlayer
                            className="w-100 h-auto p-xl-5 p-lg-4"
                            url={banner.url}
                            playing={true}
                          />
                        </div>
                      )}
                      {banner.type === "banner" && banner.banner && (
                        <div className="banner-img sliderImg p-0 m-2">
                          <img
                            src={banner.banner}
                            className="img-fluid"
                            alt={banner.imageAltText}
                            title=""
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            );
          })}
        </Slider>
      ) : (
        ""
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  banner: state.banner,
  siteSetting: state.siteSetting,
});
export default connect(mapStateToProps, { beforeBanner, getBanners, getMenu })(
  HomeSlider,
);
