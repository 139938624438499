import axios from "axios";
import CryptoJS from "crypto-js";
import { ENV } from "../../config/config";
const instance = axios.create({
  baseURL: ENV.url,
});

instance.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("accessToken") !== "") {
      config.headers["Authorization"] = ENV.Authorization;
      config.headers["x-auth-token"] = ENV.xAuthToken;
      config.headers["x-token"] = localStorage.getItem("accessToken");
      config.headers["x-r-token"] = localStorage.getItem("refreshToken");
    }
    if (config.data) {
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(config.data),
        ENV.dataEncryptionKey,
      ).toString();
      config.data = { data: encryptedData };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    if (response.data && response.data.data) {
      const bytes = CryptoJS.AES.decrypt(
        response.data.data,
        ENV.dataEncryptionKey,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      response = decryptedData;
    }
    return response;
  },
  function (error) {
    if (error.status === 419) {
      window.location.replace(window.location.origin + "/login");
      localStorage.removeItem("accessToken");
    }
    return Promise.reject(error);
  },
);

export default instance;
