import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Accordion, Card } from "react-bootstrap";
import LocalizedStrings from "react-localization";
import { beforeFaqs, getFaqList } from "./faq.action";
import localizations from "../../localization";
import { useLanguage } from "../../context/language";
import faqImg from "../../asset/image/faqs-img.svg";
import "./Faq.css";

const FAQ = (props) => {
  const [faqsQuestions, setFaqs] = useState([]);

  const { lang } = useLanguage();
  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);
  useEffect(() => {
    props.getFaqList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.beforeFaqs();
    const { getFaqList, faqList } = props.faq;
    if (getFaqList) {
      const faData = faqList?.faqs === undefined ? [] : faqList?.faqs;
      if (faData !== undefined) {
        setFaqs([...faData]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.faq.getFaqList]);

  return (
    <section className="faqs custom-container">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg="6" md="6" xs="12">
            <div className="faqs-img">
              <img src={faqImg} className="img-fluid" alt="faqs" title="" />
            </div>
          </Col>
          <Col lg="6" md="6" xs="12">
            <div className="faqs-text">
              <h2>FAQs</h2>
              <div className="faqs-accordion accordion">
                <Accordion>
                  {faqsQuestions && faqsQuestions.length > 0
                    ? faqsQuestions.map((faqques, index) => {
                        return (
                          <Card key={index}>
                            <Accordion.Item eventKey={index + 1}>
                              <Accordion.Header
                                as={Card.Header}
                                eventKey={index + 1}
                              >
                                <h3 className="accordion-header">
                                  {index + 1}.{" "}
                                  {lang === "de"
                                    ? faqques?.titleDE
                                    : faqques?.title}{" "}
                                </h3>
                              </Accordion.Header>
                              <Accordion.Body
                                eventKey={index + 1}
                                className="accordion-body border-bottom "
                              >
                                <Card.Body
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      lang === "de"
                                        ? faqques?.descDE
                                        : faqques?.desc,
                                  }}
                                ></Card.Body>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Card>
                        );
                      })
                    : ""}
                </Accordion>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  faq: state.faq,
});
export default connect(mapStateToProps, { beforeFaqs, getFaqList })(FAQ);
