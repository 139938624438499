import {
  EMPTY_ERRORS,
  GET_ERRORS,
  BEFORE_CMS,
  GET_CMS,
  GET_FOOTER_PAGES,
  GET_HEADER_PAGES,
} from "../../redux/types";
import { ENV } from "../../config/config";
import { handleResponse } from "../../util/helper";
import { getHttpRequest } from "../../asset/axios";

export const emptyError = () => {
  return {
    type: EMPTY_ERRORS,
  };
};

export const beforeCMS = () => {
  return {
    type: BEFORE_CMS,
  };
};

export const getPage =
  (slug = "") =>
  (dispatch) => {
    dispatch(emptyError());

    getHttpRequest(`${ENV.url}cms/${slug}`)
      .then(handleResponse)
      .then((data) => {
        dispatch({
          type: GET_CMS,
          payload: data,
        });
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      });
  };

export const getMenu =
  (type = "") =>
  (dispatch) => {
    dispatch(emptyError());

    getHttpRequest(`${ENV.url}cms/get-menu/${type}`)
      .then(handleResponse)
      .then((data) => {
        dispatch({
          type: GET_FOOTER_PAGES,
          payload: data,
        });
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      });
  };

export const getHeaderMenu =
  (type = "") =>
  (dispatch) => {
    dispatch(emptyError());
    getHttpRequest(`${ENV.url}cms/get-menu/${type}`)
      .then(handleResponse)
      .then((data) => {
        dispatch({
          type: GET_HEADER_PAGES,
          payload: data,
        });
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      });
  };
