import {
  EMPTY_ERRORS,
  GET_ERRORS,
  GET_PRODUCTS,
  GET_PRODUCT,
  BEFORE_PRODUCTS,
  SEARCH_PRODUCT,
  GET_MANUFACTURERS,
  GET_CATEGORIES,
} from "../../redux/types";
import { ENV } from "../../config/config";
import { handleResponse } from "../../util/helper";
import { getHttpRequest } from "../../asset/axios";
let searchAbortController = new AbortController();
let productAbortController = new AbortController();
let categoriesAbortController = new AbortController();
let productListAbortController = new AbortController();

export const emptyError = () => {
  return {
    type: EMPTY_ERRORS,
  };
};

export const beforeProducts = (data) => {
  return {
    type: BEFORE_PRODUCTS,
  };
};

export const getCategories =
  (qs = "", body = {}) =>
  async (dispatch) => {
    dispatch(emptyError());
    categoriesAbortController.abort();
    categoriesAbortController = new AbortController();
    let url = `${ENV.url}product/category/get`;
    if (qs) url += `?${qs}`;

    getHttpRequest(url)
      .then(handleResponse)
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_CATEGORIES,
            payload: data,
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      });
  };
export const getProduct = (prodId) => (dispatch) => {
  dispatch(emptyError());
  productAbortController.abort();
  productAbortController = new AbortController();
  getHttpRequest(`${ENV.url}product/get/${prodId}`)
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_PRODUCT,
          payload: data.product,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};
export const searchProducts =
  (searchQuery, sort = "") =>
  (dispatch) => {
    dispatch(emptyError());
    searchAbortController.abort();
    searchAbortController = new AbortController();
    const resellers = ENV.getUserKeys();

    getHttpRequest(
      `${ENV.url}product/search?searchquery=${searchQuery}&sortBy=${sort}&resellerId=${resellers._id}`,
    )
      .then(handleResponse)
      .then((data) => {
        if (data.success) {
          dispatch({
            type: SEARCH_PRODUCT,
            payload: data.products,
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      });
  };
export const getManufacturersList =
  (qs = "") =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}product/list-manufacturers`;

    if (qs) url += `?${qs}`;
    getHttpRequest(url)
      // .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_MANUFACTURERS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      });
  };
export const getProductsList =
  (qs = "") =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}product/list-products`;
    productListAbortController.abort();
    productListAbortController = new AbortController();
    if (qs) url += `?${qs}`;
    getHttpRequest(url)
      // .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_PRODUCTS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      });
  };
