export const checkCompanyUrl = (url) => {
  if (url && url?.length > 0) {
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    if (pattern.test(url)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const settings = {
  arrows: true,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 1650,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const formatURL = (url) => {
  if (url && url.includes("https://")) {
    return url;
  } else {
    return url ? `http://${url}` : "";
  }
};

export const handleResponse = (response) => {
  if (response.status === 401) {
    // Call the clearStorage function if the status code is 401
    // clearStorage();
    // window.location.replace('/login/en')
    return "";
  } else {
    // Handle a successful response (status code 200)
    return response;
  }
};
