import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Container, Row, Button } from "react-bootstrap";
import LocalizedStrings from "react-localization";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSkype } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import localizations from "../../localization";
import { ENV } from "../../config/config";
import { beforeContact, submitQuery } from "../contactUs/contactUs.action";
import { useLanguage } from "../../context/language";
import "./ContactForm.css";

const ContactForm = (props) => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const subjectRef = useRef(null);
  const messageRef = useRef(null);
  const { lang } = useLanguage();

  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const { customername, email, mobile } = ENV.getUserKeys();

  const [formData, setFormData] = useState({
    name: customername ?? "",
    email: email ?? "",
    phone: mobile ?? "",
    subject: "",
    message: "",
  });
  const [formDataMsg, setFormDataMsg] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [contactDetails, setContactDetails] = useState({
    skype: "",
    phone: "",
    email: "",
    location: "",
  });

  const submitQuery = () => {
    let submit = true;
    const errors = {};
    if (formData.name === "") {
      submit = false;
      errors.name = translations["Name is required"];
    }
    if (formData.email === "") {
      submit = false;
      errors.email = translations["Email is required"];
    }
    if (formData.subject === "") {
      submit = false;
      errors.subject = translations["Subject is required"];
    }
    if (formData.message === "") {
      submit = false;
      errors.message = translations["Message is required"];
    }
    setFormDataMsg(errors);
    if (submit) {
      setFormDataMsg({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
      formData.emailLanguage = lang;
      props.submitQuery(formData);
    }
  };

  const removeSpaces = (e, ref) => {
    ref.current.value = e.target.value.replace(/^\s+/, "");
  };

  useEffect(() => {
    if (props.contact.formSubmit) {
      swal({
        text: translations["Your Query has been submitted"],
        icon: "success",
        button: "OK!",
      });
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
      props.beforeContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contact.formSubmit]);

  useEffect(() => {
    if (props.siteSetting.getSettings) {
      setContactDetails({
        phone: props.siteSetting.settings.phone,
        skype: props.siteSetting.settings.skype,
        email: props.siteSetting.settings.email,
        location: props.siteSetting.settings.address,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.siteSetting.getSettings]);

  return (
    <section className="contact-form custom-container">
      <Container fluid>
        <h2>{translations["Send us Enquiries"]}</h2>
        <Row>
          <div className="col-md-6">
            <form className="contact-us-form">
              <div className="contact-us-form-box">
                <div className="mb-3">
                  <input
                    type="text"
                    onKeyUp={(e) => removeSpaces(e, nameRef)}
                    ref={nameRef}
                    className="form-control"
                    value={formData.name}
                    onInput={(event) =>
                      setFormData({ ...formData, name: event.target.value })
                    }
                    placeholder={`${translations["Name"]} ${translations["(Required)"]}`}
                    id="exampleInputText"
                  />
                  {formDataMsg.name && (
                    <small className="text-danger">{formDataMsg.name}</small>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    onKeyUp={(e) => removeSpaces(e, emailRef)}
                    ref={emailRef}
                    className="form-control"
                    value={formData.email}
                    onInput={(event) =>
                      setFormData({ ...formData, email: event.target.value })
                    }
                    placeholder={`${translations["Email"]} ${translations["(Required)"]}`}
                    id="exampleInputEmail"
                    aria-describedby="emailHelp"
                  />
                  {formDataMsg.email && (
                    <small className="text-danger">{formDataMsg.email}</small>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    type="tel"
                    onKeyUp={(e) => removeSpaces(e, phoneRef)}
                    ref={phoneRef}
                    className="form-control"
                    value={formData.phone}
                    onInput={(event) =>
                      setFormData({ ...formData, phone: event.target.value })
                    }
                    placeholder={`${translations["Phone Number"]} ${translations["(Optional)"]}`}
                    id="exampleInputPhone"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    onKeyUp={(e) => removeSpaces(e, subjectRef)}
                    ref={subjectRef}
                    className="form-control"
                    value={formData.subject}
                    onInput={(event) =>
                      setFormData({ ...formData, subject: event.target.value })
                    }
                    placeholder={`${translations["Subject"]} ${translations["(Required)"]}`}
                    id="exampleInputSubject"
                  />
                  {formDataMsg.subject && (
                    <small className="text-danger">{formDataMsg.subject}</small>
                  )}
                </div>
                <div className="contact-us-form form-floating mb-3">
                  <textarea
                    className="form-control"
                    onKeyUp={(e) => removeSpaces(e, messageRef)}
                    ref={messageRef}
                    value={formData.message}
                    onInput={(event) =>
                      setFormData({ ...formData, message: event.target.value })
                    }
                    placeholder={`${translations["Message"]} ${translations["(Required)"]}`}
                    id="floatingTextarea"
                  ></textarea>
                  {formDataMsg.message && (
                    <small className="text-danger">{formDataMsg.message}</small>
                  )}
                </div>
              </div>

              <div className="contact-us-main-btn">
                <Button className="main-btn" onClick={() => submitQuery()}>
                  {translations["Submit"]}
                </Button>
              </div>
            </form>
          </div>
          <div className="col-md-6">
            <div className="contact-us-details">
              <ul className="list-unstyled m-0">
                <li className="d-flex">
                  <div className="contact-us-details-icons-bg d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faSkype} />
                  </div>
                  <div className="flex-1">
                    <h5>{translations["Skype"]}</h5>
                    <a href={`skype:${contactDetails.skype}`}>Skype Me !</a>
                  </div>
                </li>
                <li className="d-flex">
                  <div className="contact-us-details-icons-bg d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <div className="flex-1">
                    <h5>Email</h5>
                    <a href={`mailto:${contactDetails.email}`}>
                      {contactDetails.email}
                    </a>
                  </div>
                </li>
                <li className="d-flex">
                  <div className="contact-us-details-icons-bg d-flex justify-content-center align-items-center">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <FontAwesomeIcon icon={faLocationDot} />
                  </div>
                  <div className="flex-1">
                    <h5>{translations["Location"]}</h5>
                    <a href="#.">{contactDetails.location}</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  contact: state.contact,
  siteSetting: state.siteSetting,
});
export default connect(mapStateToProps, { beforeContact, submitQuery })(
  ContactForm,
);
