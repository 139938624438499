import swal from "sweetalert";
import {
  EMPTY_ERRORS,
  GET_ERRORS,
  BEFORE_CHECKOUT,
  GET_COUNTRIES,
  ORDER_PLACED,
  GET_ORDER,
  GET_USER,
  GET_LANGUAGES,
} from "../../redux/types";
import { ENV } from "../../config/config";
import { handleResponse } from "../../util/helper";
import { getHttpRequest, postHttpRequest } from "../../asset/axios";

export const emptyError = () => {
  return {
    type: EMPTY_ERRORS,
  };
};

export const beforeCheckout = () => {
  return {
    type: BEFORE_CHECKOUT,
  };
};

export const getCountries = () => (dispatch) => {
  dispatch(emptyError());

  getHttpRequest(`${ENV.url}country/list`)
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_COUNTRIES,
          payload: data.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};

export const placeOrder = (body) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  dispatch(emptyError());

  postHttpRequest(`${ENV.url}shop/place-order`, JSON.stringify(body))
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        const langs = localStorage.getItem("language")
          ? localStorage.getItem("language")
          : "de";
        swal({
          text:
            langs === "de"
              ? "Bestellung erfolgreich aufgegeben"
              : "Order placed successfully",
          icon: "success",
          button: "OK",
        });
        dispatch({
          type: ORDER_PLACED,
          payload: data,
        });
      } else {
        swal({
          text: data.message,
          icon: "warning",
          button: "OK!",
        });
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};

export const getOrder = (orderId) => (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  dispatch(emptyError());
  const url = `${ENV.url}shop/get-order/${orderId}`;
  getHttpRequest(url)
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_ORDER,
          payload: data.order,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const getUser = (userId) => (dispatch) => {
  dispatch(emptyError());
  const url = `${ENV.url}user/${userId}`;

  getHttpRequest(url)
    .then(handleResponse)
    .then((data) => {
      if (data) {
        dispatch({
          type: GET_USER,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const getLanguages = () => (dispatch) => {
  dispatch(emptyError());

  getHttpRequest(`${ENV.url}language/list`)
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_LANGUAGES,
          payload: data.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};
