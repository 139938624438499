import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import LocalizedStrings from "react-localization";
import moment from "moment";
import Pagination from "rc-pagination";
import { beforeDashboard, getDashboardListing } from "./dashboard.action";
import TopHeader from "../header/topHeader";
import Footer from "../footer/Footer";
import { ENV } from "../../config/config";
import { currencyFormat } from "../../util/function";
import localizations from "../../localization";
import "./Dashboard.css";
import "rc-pagination/assets/index.css";

const InvoicesListing = (props) => {
  const history = useNavigate();
  const userData = ENV.getUserKeys();
  const limitOptions = [5, 10, 20, 50, 100, 150, 250];

  const [lang, setLang] = useState(localStorage.getItem("language") ?? "de");
  const [invoices, setInvoices] = useState([]);
  const [Page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState(null);

  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const onPageChange = async (page) => {
    setPage(page);
    const qs = ENV.objectToQueryString({ page: page, limit: limit });
    props.getDashboardListing(userData._id, 2, qs);
  };

  const onChangeLimit = (value) => {
    setLimit(value);
    setPage(1);
    const qs = ENV.objectToQueryString({ page: Page, limit: value });
    props.getDashboardListing(userData._id, 2, qs);
  };
  useEffect(() => {
    if (
      localStorage.getItem("language") === "en" ||
      localStorage.getItem("language") === "de"
    ) {
      translations.setLanguage(localStorage.getItem("language"));
      setLang(localStorage.getItem("language"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    props.getDashboardListing(userData._id, 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.dashboard.getListingAuth) {
      setInvoices(props.dashboard.data.data.orders);
      setPagination(props.dashboard.data.data.pagination);
      props.beforeDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboard.getListingAuth]);

  return (
    <div>
      <TopHeader />
      <div id="main">
        <div
          className="dark-green div-breadcrumbs"
          style={{
            background: "rgb(146 132 250)",
            color: "white",
            padding: "10px",
          }}
        >
          <div className="container">
            <div>
              <Link
                style={{ color: "white", "font-weight": "500" }}
                to={`/dashboard/${lang}`}
              >
                {translations["Dashboard"]}
              </Link>{" "}
              /{translations["Invoices"]}
            </div>
          </div>
        </div>
        <section className="content-section mt-3 mb-3" id="account-page">
          <Container>
            <Row className="mt-2 bottom-space">
              <Col lg="12" sm="12">
                <h3 className="mt-3 mb-3 w-100">{translations["Invoices"]}</h3>
                <div
                  id="sales-datatable_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer w-100"
                >
                  <div className="table-responsive">
                    <table
                      id="sales-datatable"
                      className="table table-bordered table-striped dataTable no-footer dtr-inline"
                    >
                      <thead>
                        <tr role="row">
                          <th>{translations["Invoice"]} #</th>
                          <th>{translations["Invoice Date"]}</th>
                          <th>{translations["Total"]}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoices.length > 0 ? (
                          invoices.map((quotation, index) => {
                            return (
                              <tr
                                role="row"
                                key={index}
                                onClick={() =>
                                  history(
                                    `/invoice-detail/${quotation._id}/${lang}`,
                                  )
                                }
                              >
                                <td>{`AVS${quotation.orderNumber.toString().padStart(5, 0)}`}</td>
                                <td>
                                  {moment(quotation.createdAt).format(
                                    "MM-DD-YYYY",
                                  )}
                                </td>
                                <td>{currencyFormat(quotation.grandTotal)}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr role="row">
                            <td colspan="3">
                              {translations["No order found"]}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="pb-4">
                    {pagination && (
                      <div className="pagination-wrapper">
                        <Pagination
                          className="m-3"
                          defaultCurrent={Page}
                          pageSize // items per page
                          current={
                            Page > pagination.pages ? pagination.pages : Page
                          } // current active page
                          total={pagination.pages} // total pages
                          onChange={onPageChange}
                        />
                        <div className="select-form-wrapper">
                          <label
                            className="remove-margin"
                            style={{ color: "black" }}
                          >
                            {" "}
                            Showing Records{" "}
                            {pagination?.total === 0
                              ? 0
                              : (Page - 1) * limit + 1}{" "}
                            to{" "}
                            {Math.min(
                              (Page - 1) * limit + limit,
                              pagination?.total,
                            )}{" "}
                            out of {pagination?.total}
                          </label>
                          <Form.Group>
                            <select
                              value={limit}
                              onChange={(e) => onChangeLimit(e.target.value)}
                            >
                              {limitOptions?.map((limitOption) => {
                                return (
                                  <>
                                    <option value={limitOption}>
                                      {limitOption}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </Form.Group>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});
export default connect(mapStateToProps, {
  beforeDashboard,
  getDashboardListing,
})(InvoicesListing);
