import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ENV } from "../../../config/config";
import swal from "sweetalert";
import localizations from "../../../localization";
import LocalizedStrings from "react-localization";
import "./templateabout.css";
import { handleResponse } from "../../../util/helper";

const contactUsSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is Invalid")
    .required("Email field is required"),
  name: Yup.string().required("Name filed is required").trim(),
  subject: Yup.string().required("Subject filed is required").trim(),
  message: Yup.string().required("Message filed is required").trim(),
  phone: Yup.string()
    .required("Phone number field is required")
    .matches(/^[0-9]+$/, "Phone number must be only digits")
    .min(10, "Phone number must contain 10 digits")
    .max(10, "Phone number must contain 10 digits"),
});

const Index = () => {
  const [lang, setLang] = useState(localStorage.getItem("language") ?? "de"); // eslint-disable-line no-unused-vars
  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const contactUsQuery = (body) => {
    fetch(`${ENV.url}contact/submit`, {
      method: "POST",
      headers: {
        Authorization: ENV.Authorization,
        "Content-Type": "application/json",
        "x-auth-token": ENV.xAuthToken,
        "x-token": localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(body),
    })
      .then(handleResponse)
      .then((data) => {
        if (data.success) {
          swal({
            text: translations["Your Query has been submitted"],
            icon: "success",
            button: "OK!",
          });
        }
      })
      .catch((errors) => {});
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      subject: "",
      phone: "",
      message: "",
    },
    validationSchema: contactUsSchema,
    onSubmit: async (values, { resetForm }) => {
      await contactUsQuery(values);
      resetForm();
    },
  });

  return (
    <div>
      <div className="container">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-4 input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="First name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <span className={formik.errors.name ? `` : `d-none`}>
                    <label className="pl-1 text-danger">
                      {formik.errors.name}
                    </label>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-4 input-wrapper">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  aria-describedby="emailHelp"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <span className={formik.errors.email ? `` : `d-none`}>
                    <label className="pl-1 text-danger">
                      {formik.errors.email}
                    </label>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-4 input-wrapper">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Phone no"
                  name="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <span className={formik.errors.phone ? `` : `d-none`}>
                    <label className="pl-1 text-danger">
                      {formik.errors.phone}
                    </label>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-4 input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  placeholder="Subject"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.subject}
                />
                {formik.touched.subject && formik.errors.subject ? (
                  <span className={formik.errors.subject ? `` : `d-none`}>
                    <label className="pl-1 text-danger">
                      {formik.errors.subject}
                    </label>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="mb-4 input-wrapper">
                <textarea
                  className="form-control"
                  placeholder="Message"
                  name="message"
                  id="floatingTextarea2"
                  rows="5"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                ></textarea>
                {formik.touched.message && formik.errors.message ? (
                  <span className={formik.errors.message ? `` : `d-none`}>
                    <label className="pl-1 text-danger">
                      {formik.errors.message}
                    </label>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="form-button">
              <button type="submit" className="btn-blue">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
