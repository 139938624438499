import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocalizedStrings from "react-localization";
import { loginViaAdminAction, beforeLogin, getUserCart } from "./login.action";
import { ENV } from "../../config/config";
import {
  getVATforCountry,
  getSettings,
} from "../siteSetting/siteSetting.action";
import { beforeCart, addToUserCart } from "../cart/cart.action";
import localizations from "../../localization";
import { useLanguage } from "../../context/language";
import { _localStorage } from "../../classes/_localStorage";
import LoginImg from "../../asset/image/side-logo.svg";

const AdminLoginRequest = (props) => {
  const { lang } = useLanguage();
  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);
  const history = useNavigate();
  const [redirecting, setRedirecting] = useState(false);
  useEffect(() => {
    if (props.login.loginActionAuth) {
      const userData = ENV.getUserKeys();
      props.getUserCart(userData._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.login.loginActionAuth]);

  useEffect(() => {
    if (props.login.cartAuth) {
      if (props.login.cart) {
        ENV.setLocalCart(props.login.cart);
      }
      const userData = ENV.getUserKeys();
      const cartParams = {
        emailLanguage: localStorage.getItem("language"),
        userId: userData._id,
        isCheckout: false,
        items: JSON.parse(_localStorage.getItem("cart")),
      };
      props.addToUserCart(cartParams);
      props.beforeCart();
      props.beforeLogin();
      history(`/${localStorage.getItem("language")}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.login.cartAuth]);

  useEffect(() => {
    if (props.cart.cartAddedAuth) {
      props.beforeCart();
      props.beforeLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cart.cartAddedAuth]);
  const login = () => {
    const pathDetails = window.location.pathname.split("/");
    const payload = {
      userId: pathDetails[2],
      token: pathDetails[3],
    };
    props.loginViaAdminAction(payload);
  };
  return (
    <section className="wrapper account-verification">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg="4" md="4" xs="12" className="pl-0 pr-0">
            <div className="form-side-display d-flex justify-content-center align-items-center">
              <Link to={`/${lang}`} className="d-inline-block">
                <img src={LoginImg} alt="logo" className="img-fluid" />
              </Link>
            </div>
          </Col>
          <Col lg="8" md="8" xs="12" className="pl-0 pr-0 ">
            <div className="login-main new-logged-des">
              <div className="login-main-child">
                <h6>{translations["Welcome Admin"]}</h6>
                <h2>
                  {translations["You are trying to access a reseller account."]}
                </h2>
                <div className="row align-items-center">
                  <div className="contact-us-main-btn">
                    <button
                      onClick={(e) => login(e)}
                      className="main-btn d-inline-block"
                    >
                      {translations["Sign In"]}
                    </button>{" "}
                    :
                  </div>
                </div>
                {redirecting && (
                  <h6>{translations["Redirecting to the site"]}</h6>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  siteSetting: state.siteSetting,
  login: state.login,
  cart: state.cart,
});
export default connect(mapStateToProps, {
  beforeLogin,
  beforeCart,
  addToUserCart,
  loginViaAdminAction,
  getUserCart,
  getVATforCountry,
  getSettings,
})(AdminLoginRequest);
