import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LocalizedStrings from "react-localization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { beforeDashboard, getDashboardData } from "./dashboard.action";
import TopHeader from "../header/topHeader";
import Footer from "../footer/Footer";
import { ENV } from "../../config/config";
import localizations from "../../localization";
import { useLanguage } from "../../context/language";
import "./Dashboard.css";

const Dashboard = (props) => {
  const { lang } = useLanguage();
  const user = ENV.getUserKeys();
  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const [dashboardData, setDashboardData] = useState({
    quotations: 0,
    salesOrders: 0,
    refundedOrders: 0,
    invoices: 0,
  });
  useEffect(() => {
    props.getDashboardData(user._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.dashboard.getDataAuth) {
      setDashboardData(props.dashboard.data);
      props.beforeDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboard.getDataAuth]);

  return (
    <div>
      <TopHeader />
      <div id="main">
        <div
          className="dark-green div-breadcrumbs"
          style={{
            background: "rgb(146 132 250)",
            color: "white",
            padding: "10px",
          }}
        >
          <div className="container">
            <div>{translations["Dashboard"]}</div>
          </div>
        </div>
        <section
          className="content-section userdashboard-page"
          id="account-page"
        >
          <Container>
            <div className="mt-4 row bottom-space">
              <Container>
                <Row>
                  <div className="col-lg-8">
                    <h3>{translations["Documents"]}</h3>
                    <div className="o_portal_docs list-group">
                      <Link
                        className="list-group-item list-group-item-action d-flex align-items-center justify-content-between"
                        to={`/quotation-listing/${lang}`}
                        title={translations["Quotations"]}
                      >
                        {translations["Quotations"]}
                        <span className="badge badge-secondary badge-pill">
                          {dashboardData.quotations}
                        </span>
                      </Link>
                      <Link
                        className="list-group-item list-group-item-action d-flex align-items-center justify-content-between"
                        to={`/sales-order-listing/${lang}`}
                        title={translations["Sales Orders"]}
                      >
                        {translations["Sales Orders"]}
                        <span className="badge badge-secondary badge-pill">
                          {dashboardData.salesOrders}
                        </span>
                      </Link>
                      <Link
                        className="list-group-item list-group-item-action d-flex align-items-center justify-content-between"
                        to={`/refunded-order-listing/${lang}`}
                        title={translations["Refunded Orders"]}
                      >
                        {translations["Refunded Orders"]}
                        <span className="badge badge-secondary badge-pill">
                          {dashboardData.refundedOrders}
                        </span>
                      </Link>
                      <Link
                        className="list-group-item list-group-item-action d-flex align-items-center justify-content-between"
                        to={`/invoice-listing/${lang}`}
                        title="Invoices &amp; Bills"
                      >
                        {translations["Invoices"]}
                        <span className="badge badge-secondary badge-pill">
                          {dashboardData.invoices}
                        </span>
                      </Link>
                    </div>
                  </div>
                  {user && (
                    <div className="col-lg-4 shop-search-bar">
                      <Row>
                        <div className="col-lg-12">
                          <div className="dashboard-user-detail">
                            <h3 className="text-uppercase">
                              {" "}
                              {user.customername}{" "}
                            </h3>
                            <address className="mb-0">
                              <div itemprop="address">
                                {user &&
                                  user?.addresses?.length > 0 &&
                                  user.addresses[0] && (
                                    <div className="d-flex">
                                      <span>
                                        <FontAwesomeIcon icon={faLocationDot} />
                                      </span>
                                      <div className="info-holder">
                                        <h2 className="text-capitalize">
                                          {translations["Location"]}
                                        </h2>
                                        <p
                                          className="w-100 o_force_ltr d-block"
                                          itemprop="streetAddress"
                                        >
                                          {user.addresses[0].street}
                                          <br />
                                          {user.addresses[0].city} <br />
                                          {user.addresses[0].country}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                <div className="d-flex">
                                  <span>
                                    <FontAwesomeIcon icon={faPhone} />
                                  </span>
                                  <div className="info-holder">
                                    <h2 className="text-capitalize">
                                      {translations["Contact Us"]}
                                    </h2>
                                    <a
                                      href={`tel:${user.mobile}`}
                                      className="o_force_ltr"
                                      itemprop="telephone"
                                    >
                                      {user.mobile}
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <span>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                  </span>
                                  <div className="info-holder">
                                    <h2 className="text-capitalize">
                                      {translations["Email"]}
                                    </h2>
                                    <a
                                      href={`mailto:${user.email}`}
                                      itemprop="email"
                                    >
                                      {user.email}
                                    </a>
                                  </div>
                                </div>
                                <div className="mt-3">
                                  <Link
                                    className="btn main-btn"
                                    to={`/user-profile/${lang}`}
                                  >
                                    {translations["Profile"]}
                                  </Link>
                                </div>
                              </div>
                            </address>
                          </div>
                        </div>
                      </Row>
                    </div>
                  )}
                </Row>
              </Container>
            </div>
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});
export default connect(mapStateToProps, { beforeDashboard, getDashboardData })(
  Dashboard,
);
