import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocalizedStrings from "react-localization";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { loginAction, beforeLogin, getUserCart } from "./login.action";
import { ENV } from "../../config/config";
import {
  getVATforCountry,
  getSettings,
} from "../siteSetting/siteSetting.action";
import { beforeCart, addToUserCart } from "../cart/cart.action";
import localizations from "../../localization";
import { useLanguage } from "../../context/language";
import { _localStorage } from "../../classes/_localStorage";
import LoginImg from "../../asset/image/side-logo.svg";

import "react-loading-skeleton/dist/skeleton.css";

const Login = (props) => {
  const history = useNavigate();
  const { lang } = useLanguage();
  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const [msg, setMsg] = useState({
    email: "",
    passowrd: "",
  });

  const login = (e) => {
    e.preventDefault();
    const payload = {
      email: userData.email,
      password: userData.password,
    };

    setMsg({
      email: "",
      passowrd: "",
    });
    setLoader(true);
    props.loginAction(payload);
  };
  useEffect(() => {
    if (props.errors?.message) {
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);
  const showPasswordMethod = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (props.login.loginActionAuth) {
      setLoader(false);
      const userData = ENV.getUserKeys();
      props.getUserCart(userData._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.login.loginActionAuth]);

  useEffect(() => {
    if (props.login.cartAuth) {
      if (props.login.cart) {
        ENV.setLocalCart(props.login.cart);
      }
      const userData = ENV.getUserKeys();
      const cartParams = {
        emailLanguage: localStorage.getItem("language"),
        userId: userData._id,
        isCheckout: false,
        items: JSON.parse(_localStorage.getItem("cart")),
      };
      props.addToUserCart(cartParams);
      props.beforeCart();
      props.beforeLogin();
      if (localStorage.getItem("login_redirect_url")) {
        history(localStorage.getItem("login_redirect_url"));
        localStorage.setItem("login_redirect_url", "");
      } else {
        history(`/${localStorage.getItem("language")}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.login.cartAuth]);

  useEffect(() => {
    if (props.cart.cartAddedAuth) {
      props.beforeCart();
      props.beforeLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cart.cartAddedAuth]);

  return (
    <section className="wrapper account-verification">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg="4" md="4" xs="12" className="px-0">
            <div className="form-side-display d-flex justify-content-center align-items-center">
              <Link to={`/${lang}`} className="d-inline-block">
                <img src={LoginImg} alt="logo" className="img-fluid" />
              </Link>
            </div>
          </Col>
          <Col lg="8" md="8" xs="12" className="px-0 ">
            <div className="login-main new-logged-des">
              <div className="login-main-child">
                <h6>{translations["Welcome Back"]}</h6>
                <h2>{translations["Login to your account"]}</h2>
                <form className="login-form-main">
                  <div className="mb-3 login-form-input">
                    <input
                      type="email"
                      className="form-control"
                      placeholder={`name@domain.com ${translations["(Required)"]}`}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={userData.email}
                      autoFocus={true}
                      onChange={(e) =>
                        setUserData({ ...userData, email: e.target.value })
                      }
                    />
                    {msg.email && <small className="error">{msg.email}</small>}
                  </div>
                  <div className="mb-3 login-form-input form-password-eye-box">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder={`${translations["Password"]} ${translations["(Required)"]}`}
                      id="exampleInputPassword1"
                      value={userData.password}
                      onChange={(e) =>
                        setUserData({ ...userData, password: e.target.value })
                      }
                    />
                    <button
                      onClick={(e) => showPasswordMethod(e)}
                      className="form-password-eye"
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                      />
                    </button>
                    {msg.passowrd && (
                      <small className="error">{msg.passowrd}</small>
                    )}
                  </div>
                  <div className="mb-4 form-check pl-0 d-flex justify-content-between">
                    <div className="mb-4 form-check d-flex justify-content-between custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="defaultChecked"
                      />
                      <label
                        className="custom-control-label remember-me-checkbox"
                        htmlFor="defaultChecked"
                      >
                        {translations["Remember Me"]}
                      </label>
                    </div>
                    <Link to={`/forget-password/${lang}`} id="forget-password">
                      {translations["Forget Password?"]}
                    </Link>
                  </div>
                  <div className="row align-items-center recaptcha-btn-wrap ">
                    <div className="col-lg-12 col-md-12">
                      <div className="contact-us-main-btn">
                        {!loader ? (
                          <button
                            onClick={(e) => login(e)}
                            className="main-btn d-inline-block"
                          >
                            {translations["Sign In"]}
                          </button>
                        ) : (
                          <Nav.Item className="link-unstyled col-md-6">
                            <SkeletonTheme
                              color="#202020"
                              highlightColor="#7E9DF3"
                            >
                              <p>
                                <Skeleton count={3} />
                              </p>
                            </SkeletonTheme>
                          </Nav.Item>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Link to={`/register/${lang}`} id="register-new-user">
                      {translations["Register As New Customer"]}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  siteSetting: state.siteSetting,
  login: state.login,
  cart: state.cart,
});
export default connect(mapStateToProps, {
  beforeLogin,
  beforeCart,
  addToUserCart,
  loginAction,
  getUserCart,
  getVATforCountry,
  getSettings,
})(Login);
