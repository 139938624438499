import React, { useState, Fragment, useEffect, useRef } from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import LocalizedStrings from "react-localization";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPencil } from "@fortawesome/free-solid-svg-icons";
import TopHeader from "../header/topHeader";
import Footer from "../footer/Footer";
import {
  beforeProfile,
  updateProfile,
  uploadExtract,
  sendEmailAgain,
} from "./userProfile.action";
import {
  getCountries,
  getUser,
  beforeCheckout,
} from "../checkout/checkout.action";
import { ENV } from "../../config/config";
import localizations from "../../localization";
import { checkCompanyUrl } from "../../util/helper";
import { useLanguage } from "../../context/language";
import userProfileImg from "../../asset/image/avatar.png";
import Prompt from "./prompt";

const UserProfile = (props) => {
  const { lang } = useLanguage();
  const myRef = useRef(null);
  const { _id, customername, email, addresses, mobile, confirmationCode } =
    ENV.getUserKeys();
  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const [formState, setFormState] = useState("unchanged");
  const [profileImage, setProfileImage] = useState("");
  const [countriesList, setCountriesList] = useState();
  const [msg, setMsg] = useState({
    name: "",
    email: "",
    street: "",
    country: "",
    companyName: "",
    cityName: "",
    password: "",
    confirmPassword: "",
    companyURL: "",
    zipCode: "",
    companyVAT: "",
  });
  const [userData, setUserData] = useState({
    customername,
    email,
    mobile,
    password: "",
    confirmPassword: "",
    countryName: addresses[0] ? addresses[0].country : "",
    cityName: addresses[0] ? addresses[0].city : "",
    street: addresses[0] ? addresses[0].street : "",
    state: addresses[0] ? addresses[0].state : "",
    zipCode: addresses[0] ? addresses[0].zipCode : "",
    companyType: "",
    companyName: "",
    companyURL: "",
    companyVAT: "",
    companyRegistrationExtact: "",
  });

  const handleCountryChange = (option) => {
    setUserData({
      ...userData,
      countryName: option.label,
    });
  };

  const fileSelectHandler = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(files[0]);
    }
  };

  const fileExtractSelectHandler = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(files[0]);
    }
  };

  const handleSubmit = () => {
    setFormState("unchanged");
    const data = {
      profileImage: profileImage,
      customername: userData.customername,
      email: userData.email,
      mobile: userData.mobile,
      countryName: userData.countryName,
      cityName: userData.cityName,
      street: userData.street,
      state: userData.state,
      zipCode: userData.zipCode,
      companyType: userData.companyType,
      companyName: userData.companyName,
      companyVAT: userData.companyVAT,
      companyURL: userData.companyURL,
      companyRegistrationExtact: userData.companyRegistrationExtact,
    };
    let allowSubmit = true;

    setMsg({
      name: !userData.customername ? translations["Name is required"] : "",
      email: !userData.email ? translations["Email is required"] : "",
      street: !userData.street ? translations["Street is required"] : "",
      country: !userData.countryName ? translations["Country is required"] : "",
      cityName: !userData.cityName ? translations["City is required"] : "",
      zipCode: !userData.zipCode ? translations["Zip Code is required"] : "",
      companyName:
        userData.companyType && !userData.companyName
          ? translations["Company Name is required"]
          : "",
      companyURL: userData.companyType
        ? !checkCompanyUrl(userData.companyURL)
          ? translations["Company url is required"]
          : ""
        : "",
      companyVAT: userData.companyType
        ? !userData.companyVAT
          ? translations["Company VAT is required"]
          : ""
        : "",
    });

    if (
      !userData.customername ||
      !userData.zipCode ||
      (userData.companyType && !userData.companyVAT) ||
      !userData.email ||
      !userData.street ||
      !userData.countryName ||
      !userData.cityName ||
      (userData.companyType && !userData.companyName) ||
      (userData.companyType && !checkCompanyUrl(userData.companyURL))
    ) {
      allowSubmit = false;
      setFormState("modified");
    }
    if (userData.password !== "") {
      if (
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          userData.password,
        )
      ) {
        if (userData.password === userData.confirmPassword) {
          data.password = userData.password;
          setMsg({ ...msg, confirmPassword: "" });
        } else {
          allowSubmit = false;
          setMsg({
            ...msg,
            confirmPassword: translations["Password does not match"],
          });
          setFormState("modified");
        }
      } else {
        setFormState("modified");
        allowSubmit = false;
        setMsg({
          ...msg,
          password:
            translations[
              "Password must be 8 characters with atleast one character, capital and small alphabet with a number"
            ],
        });
      }
    }
    if (
      userData.confirmPassword !== "" &&
      userData.password !== userData.confirmPassword
    ) {
      allowSubmit = false;
      setMsg({
        ...msg,
        confirmPassword: translations["Password does not match"],
      });
      setFormState("modified");
    }
    if (allowSubmit) {
      setMsg({
        name: "",
        email: "",
        street: "",
        country: "",
        password: "",
        confirmPassword: "",
        zipCode: "",
        companyVAT: "",
      });
      props.updateProfile(_id, data);
    } else {
      myRef.current.scrollIntoView();
    }
  };

  const sendVerificationEmail = () => {
    props.sendEmailAgain(_id);
  };

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };
    // if the form is NOT unchanged, then set the onbeforeunload
    if (formState !== "unchanged") {
      window.addEventListener("beforeunload", handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    // since this is not dirty, don't do anything
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  useEffect(() => {
    props.beforeCheckout();
    props.getUser(_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.profile.upsertProfile) {
      swal({
        text: translations["Profile Updated"],
        icon: "success",
        button: "OK",
      });
      ENV.encryptUserData(props.profile.data);
      props.getUser(_id);
      props.beforeProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.profile.upsertProfile]);

  useEffect(() => {
    props.getUser(_id);
    props.beforeProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.profile.upsertExtract) {
      setUserData({
        ...userData,
        companyRegistrationExtact:
          ENV.uploadedImgPath + "/" + props.profile.extract.path,
      });
      props.beforeProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.profile.upsertExtract]);

  useEffect(() => {
    if (props.checkout.getCountries) {
      const countryArray = props.checkout.countries;
      const countryList = Object.keys(countryArray).map((item) => ({
        label: countryArray[item].name,
        value: countryArray[item].name,
      }));
      setCountriesList(countryList);
      if (userData.countryName) {
        handleCountryChange({ label: userData.countryName });
      }
      props.beforeCheckout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkout.getCountries]);

  useEffect(() => {
    const setDataOfUser = () => {
      if (props.checkout.getUser) {
        const user = props.checkout.user.data;
        setProfileImage(
          user.profileImage
            ? ENV.uploadedImgPath + "/" + user.profileImage
            : userProfileImg,
        );
        if (user) {
          setUserData({
            customername: user.customername,
            email: user.email,
            mobile: user.mobile,
            password: "",
            confirmPassword: "",
            countryName: user.addresses[0] ? user.addresses[0].country : "",
            cityName: user.addresses[0] ? user.addresses[0].city : "",
            street: user.addresses[0] ? user.addresses[0].street : "",
            state: user.addresses[0] ? user.addresses[0].state : "",
            zipCode: user.addresses[0] ? user.addresses[0].zipCode : "",
            companyType: user.companyType,
            companyName: user.companyName,
            companyURL: user.companyURL,
            companyVAT: user.companyVAT,
            companyRegistrationExtact: user.companyRegistrationExtact,
          });
        }
        props.getCountries();
        props.beforeCheckout();
      }
    };
    setDataOfUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkout.getUser]);

  return (
    <Fragment>
      <TopHeader />
      <Prompt
        when={formState !== "unchanged"}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <div id="main">
        <section className="user-profile contact-form custom-container">
          <form className="contact-us-form vouchers-form">
            <Container fluid>
              <Row>
                <div
                  className="vouchers-form-row mb-2 profile-main-hover text-center"
                  ref={myRef}
                >
                  <div className="user-profile-img d-inline-block">
                    <img
                      src={profileImage ? profileImage : userProfileImg}
                      alt=""
                      className="img-fluid"
                    />
                    <div className="user-profile-img-hover">
                      <form>
                        <label
                          htmlFor="myfile"
                          className="user-profile-img-hover-label"
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </label>
                        <div className="d-none">
                          <input
                            type="file"
                            id="myfile"
                            name="myfile"
                            onChange={async (e) => {
                              e.preventDefault();
                              let files;
                              if (e.dataTransfer) {
                                files = e.dataTransfer.files;
                              } else if (e.target) {
                                files = e.target.files;
                              }
                              if (files.length > 0) {
                                fileSelectHandler(e);
                                const res = await ENV.uploadImage(e);
                                setProfileImage(
                                  res ? ENV.uploadedImgPath + "/" + res : "",
                                );
                              }
                              setFormState("modified");
                            }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <small
                  className="mb-4 text-success "
                  style={{ textAlign: "center" }}
                >
                  {translations["Recommended Image Size"]}: 400 x 400px
                </small>
                <div className="vouchers-form-row mb-4 d-flex justify-content-between align-items-center">
                  <div className="voucher-w-50 voucher-row-me">
                    <label htmlFor="exampleInputText1" className="form-label">
                      {translations["Name"]}
                      <span id="form-label-required">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputText"
                        value={userData.customername}
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            customername: e.target.value,
                          });
                          setFormState("modified");
                        }}
                      />
                      {msg.name && <small className="error">{msg.name}</small>}
                    </div>
                  </div>
                  <div className="voucher-w-50">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {translations["Email"]}
                      <span id="form-label-required">*</span>
                    </label>
                    <div className="email-field-wrapper position-relative">
                      <input
                        readOnly="true"
                        type="email"
                        className="form-control"
                        id="exampleInputEmail"
                        aria-describedby="emailHelp"
                        value={userData.email}
                        onChange={(e) =>
                          setUserData({ ...userData, email: e.target.value })
                        }
                      />
                      {msg.email && (
                        <small className="error">{msg.email}</small>
                      )}
                      {!confirmationCode ? (
                        <div className="vouchers-form-btn-group d-flex justify-content-start">
                          <Link
                            to="#"
                            className="main-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              sendVerificationEmail();
                            }}
                          >
                            {
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                className="email-icon"
                              />
                            }
                          </Link>
                          <h6>Send Verification Email</h6>
                        </div>
                      ) : (
                        <span className="verified-mail">Email verified</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="vouchers-form-row mb-4 d-flex justify-content-between align-items-center">
                  <div className="voucher-w-50 voucher-row-me">
                    <label htmlFor="exampleInputTel1" className="form-label">
                      {translations["Mobile"]}
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPhone"
                        value={userData.mobile}
                        onChange={(e) => {
                          setUserData({ ...userData, mobile: e.target.value });
                          setFormState("modified");
                        }}
                      />
                    </div>
                  </div>
                  <div className="voucher-w-50">
                    <label htmlFor="exampleInputText1" className="form-label">
                      {translations["State"]}
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputText1"
                        value={userData.state}
                        onChange={(e) => {
                          setUserData({ ...userData, state: e.target.value });
                          setFormState("modified");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="vouchers-form-row mb-4 d-flex justify-content-between align-items-center">
                  <div className="voucher-w-50 voucher-row-me">
                    <label htmlFor="exampleInputText1" className="form-label">
                      {translations["Street"]}
                      <span id="form-label-required">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputText"
                        value={userData.street}
                        onChange={(e) => {
                          setUserData({ ...userData, street: e.target.value });
                          setFormState("modified");
                        }}
                      />
                      {msg.street && (
                        <small className="error">{msg.street}</small>
                      )}
                    </div>
                  </div>
                  <div className="voucher-w-50">
                    <label htmlFor="exampleInputText1" className="form-label">
                      {translations["Zip Code"]}{" "}
                      <span id="form-label-required">*</span>
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputText1"
                        value={userData.zipCode}
                        onChange={(e) => {
                          setUserData({ ...userData, zipCode: e.target.value });
                          setFormState("modified");
                        }}
                      />
                      {msg.zipCode && (
                        <small className="error">{msg.zipCode}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="vouchers-form-row mb-4 d-flex justify-content-between align-items-end user-profile-select">
                  <div className="voucher-w-50 voucher-row-me">
                    <label htmlFor="exampleInputSelect1" className="form-label">
                      Country<span id="form-label-required">*</span>
                    </label>
                    <div className="form-floating login-form-input">
                      <Select
                        options={countriesList}
                        placeholder="Select Country"
                        onChange={(option) => {
                          handleCountryChange(option);
                          setFormState("modified");
                        }}
                        value={countriesList?.filter(
                          (option) => option.value === userData.countryName,
                        )}
                      />
                      {msg.country && (
                        <small className="error">{msg.country}</small>
                      )}
                    </div>
                  </div>
                  <div className="voucher-w-50">
                    <label htmlFor="exampleInputSelect2" className="form-label">
                      {translations["City"]}
                      <span id="form-label-required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={`${translations["City"]} ${translations["(Required)"]}`}
                      value={userData.cityName}
                      onChange={(e) =>
                        setUserData({ ...userData, cityName: e.target.value })
                      }
                    />
                    {msg.cityName && (
                      <small className="error">{msg.cityName}</small>
                    )}
                  </div>
                </div>
                {userData.companyType ? (
                  <>
                    <div className="vouchers-form-row mb-4 d-flex justify-content-between align-items-center">
                      <div className="voucher-w-50 voucher-row-me">
                        <label
                          htmlFor="exampleInputPassword1"
                          className="form-label"
                        >
                          {translations["Company Name"]}
                          <span id="form-label-required">*</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword"
                            value={userData.companyName}
                            onChange={(e) => {
                              setUserData({
                                ...userData,
                                companyName: e.target.value,
                              });
                              setFormState("modified");
                            }}
                          />
                          {msg.companyName && (
                            <small className="error">{msg.companyName}</small>
                          )}
                        </div>
                      </div>
                      <div className="voucher-w-50">
                        <label
                          htmlFor="exampleInputPassword1"
                          className="form-label"
                        >
                          {translations["Company URL"]}
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword"
                            value={userData.companyURL}
                            onChange={(e) => {
                              setUserData({
                                ...userData,
                                companyURL: e.target.value,
                              });
                              setFormState("modified");
                            }}
                          />
                          {msg.companyURL && (
                            <small className="error">{msg.companyURL}</small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="vouchers-form-row mb-4 d-flex justify-content-between align-items-center">
                      <div className="voucher-w-50 voucher-row-me">
                        <label
                          htmlFor="exampleInputPassword1"
                          className="form-label"
                        >
                          {translations["Company VAT"]}
                          <span id="form-label-required">*</span>
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword"
                            value={userData.companyVAT}
                            onChange={(e) => {
                              setUserData({
                                ...userData,
                                companyVAT: e.target.value,
                              });
                              setFormState("modified");
                            }}
                          />
                          {msg.companyVAT && (
                            <small className="error">{msg.companyVAT}</small>
                          )}
                        </div>
                      </div>
                      <div className="voucher-w-50">
                        <label
                          htmlFor="exampleInputPassword1"
                          className="form-label"
                        >
                          {translations["Company Registration Extract"]}
                        </label>
                        <div className="d-flex align-items-center input-extract-wrap">
                          <input
                            type="file"
                            id="myfile"
                            name="myfile"
                            onChange={async (e) => {
                              e.preventDefault();
                              let files;
                              if (e.dataTransfer) {
                                files = e.dataTransfer.files;
                              } else if (e.target) {
                                files = e.target.files;
                              }
                              if (files.length > 0) {
                                fileExtractSelectHandler(e);
                                const res = await ENV.uploadImage(e, "extract");
                                setUserData({
                                  ...userData,
                                  companyRegistrationExtact: res
                                    ? ENV.uploadedImgPath + "/" + res
                                    : "",
                                });
                              }
                              setFormState("modified");
                            }}
                          />
                          {userData.companyRegistrationExtact && (
                            <div>
                              <a
                                className="btn btn-primary main-btn extract-btn"
                                target="_blank"
                                rel="noreferrer"
                                href={userData.companyRegistrationExtact}
                              >
                                View Extract
                              </a>
                              <p>
                                {userData.companyRegistrationExtact
                                  .split("/")
                                  .pop()}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="vouchers-form-row mb-4 user-profile-password d-flex justify-content-between align-items-center">
                  <div className="voucher-w-50 voucher-row-me">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      {translations["New Password"]}
                    </label>
                    <div>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword"
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            password: e.target.value,
                          });
                        }}
                      />
                      {msg.password && (
                        <small className="error">{msg.password}</small>
                      )}
                    </div>
                  </div>
                  <div className="voucher-w-50">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      {translations["Confirm Password"]}
                    </label>
                    <div>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword"
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            confirmPassword: e.target.value,
                          });
                        }}
                      />
                      {msg.confirmPassword && (
                        <small className="error">{msg.confirmPassword}</small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="vouchers-form-btn-group d-flex justify-content-start">
                  <Link
                    to="#"
                    className="main-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setFormState("unchanged");
                      handleSubmit();
                    }}
                  >
                    {translations["Update"]}
                  </Link>
                </div>
              </Row>
            </Container>
          </form>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  login: state.login,
  checkout: state.checkout,
  profile: state.profile,
});
export default connect(mapStateToProps, {
  getCountries,
  beforeProfile,
  updateProfile,
  getUser,
  uploadExtract,
  beforeCheckout,
  sendEmailAgain,
})(UserProfile);
