import CryptoJS from "crypto-js";
import { ENV } from "../config/config";

// const SECRET_KEY = ENV.dataEncryptionKey;

export function encrypt(data) {
  return CryptoJS.AES.encrypt(data, ENV.dataEncryptionKey).toString();
}

export function decrypt(data) {
  const bytes = CryptoJS.AES.decrypt(data, ENV.dataEncryptionKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}
