import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import LocalizedStrings from "react-localization";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  registerAction,
  beforeLogin,
  loginAction,
} from "../login/login.action";
import { getCountries } from "../checkout/checkout.action";
import { ENV } from "../../config/config";
import localizations from "../../localization";
import { checkCompanyUrl } from "../../util/helper";
import { useLanguage } from "../../context/language";
import LoginImg from "../../asset/image/side-logo.svg";
import frontLoader from "../../asset/image/front-loader.gif";
import "./Register.css";

const Register = (props) => {
  const { lang } = useLanguage();
  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const history = useNavigate();
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const companyRef = useRef(null);
  const mobileRef = useRef(null);
  const stateRef = useRef(null);
  const streetRef = useRef(null);
  const zipCodeRef = useRef(null);
  const cityNameRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const [countriesList, setCountriesList] = useState();
  const [userData, setUserData] = useState({
    customername: "",
    email: "",
    password: "",
    confirmPassword: "",
    countryName: "",
    cityName: "",
    companyName: "",
    mobile: "",
    state: "",
    street: "",
    zipCode: "",
    companyType: true,
    companyURL: "",
    companyVat: "",
  });
  const [msg, setMsg] = useState({
    customername: "",
    email: "",
    password: "",
    confirmPassword: "",
    countryName: "",
    mobile: "",
    street: "",
    cityName: "",
    companyName: "",
    companyURL: "",
    companyVat: "",
    zipCode: "",
  });
  const checkPhoneNo = (text) => {
    const phoneNumberRegex = /^[+\d]+$/;
    if (text?.length > 0) {
      if (phoneNumberRegex.test(text)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const register = (e) => {
    e.preventDefault();
    if (
      userData.customername &&
      userData.mobile &&
      checkPhoneNo(userData.mobile) &&
      userData.email &&
      userData.zipCode &&
      userData.password &&
      userData.confirmPassword &&
      userData.countryName &&
      (userData.companyType ? userData.companyName : true)
    ) {
      if (
        userData.companyVat &&
        userData.companyName &&
        !checkCompanyUrl(userData.companyURL)
      ) {
        setMsg({
          customername: !userData.customername
            ? translations["Name is required"]
            : "",
          email: !userData.email ? translations["Email is required"] : "",
          mobile: !userData.mobile
            ? translations["Mobile is required"]
            : !checkPhoneNo(userData.mobile)
              ? translations["Enter a valid Mobile number"]
              : "",
          password: !userData.password
            ? translations["Password is required"]
            : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                  userData.password,
                )
              ? ""
              : translations[
                  "Password must be 8 characters with atleast one character, capital and small alphabet with a number"
                ],
          confirmPassword:
            userData.confirmPassword !== userData.password
              ? translations["Password does not match"]
              : "",
          countryName: !userData.countryName
            ? translations["Country is required"]
            : "",
          cityName: !userData.cityName ? translations["City is required"] : "",
          street: !userData.street ? translations["Street is required"] : "",
          zipCode: !userData.zipCode
            ? translations["Zip Code is required"]
            : "",
          companyName: userData.companyType
            ? !userData.companyName
              ? translations["Company name is required"]
              : ""
            : "",
          companyVat: userData.companyType
            ? !userData.companyVat
              ? translations["Company vat is required"]
              : ""
            : "",
          companyURL: userData.companyType
            ? !checkCompanyUrl(userData.companyURL)
              ? translations["Company url is required"]
              : ""
            : "",
        });
        return false;
      }
      if (userData.password !== userData.confirmPassword) {
        swal({
          text: translations["Passwords do not match"],
          icon: "error",
          button: "OK!",
        });
        return;
      }

      setMsg({
        customername: "",
        email: "",
        password: "",
        confirmPassword: "",
        countryName: "",
        cityName: "",
        street: "",
        mobile: "",
        zipCode: "",
      });

      const payload = {
        customername: userData.customername,
        email: userData.email,
        password: userData.password,
        countryName: userData.countryName,
        companyName: userData.companyName,
        emailLanguage: lang,
        state: userData.state,
        zipCode: userData.zipCode,
        mobile: userData.mobile,
        street: userData.street,
        cityName: userData.cityName,
        companyType: userData.companyType,
        companyVAT: userData.companyVat,
        companyURL: userData.companyURL,
      };
      props.registerAction(payload);
      props.beforeLogin();
    } else {
      setMsg({
        customername: !userData.customername
          ? translations["Name is required"]
          : "",
        email: !userData.email ? translations["Email is required"] : "",
        mobile: !userData.mobile ? translations["Mobile is required"] : "",
        password: !userData.password
          ? translations["Password is required"]
          : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                userData.password,
              )
            ? ""
            : translations[
                "Password must be 8 characters with atleast one character, capital and small alphabet with a number"
              ],
        confirmPassword:
          userData.confirmPassword !== userData.password
            ? translations["Password does not match"]
            : "",
        countryName: !userData.countryName
          ? translations["Country is required"]
          : "",
        cityName: !userData.cityName ? translations["City is required"] : "",
        zipCode: !userData.zipCode ? translations["Zip Code is required"] : "",
        street: !userData.street ? translations["Street is required"] : "",
        companyName: userData.companyType
          ? !userData.companyName
            ? translations["Company name is required"]
            : ""
          : "",
        companyVat: userData.companyType
          ? !userData.companyVat
            ? translations["Company vat is required"]
            : ""
          : "",
        companyURL: userData.companyType
          ? !checkCompanyUrl(userData.companyURL)
            ? translations["Company url is required"]
            : ""
          : "",
      });
    }
  };

  const removeSpaces = (e, ref) => {
    ref.current.value = e.target.value.replace(/^\s+/, "");
  };

  const login = (payload) => {
    props.loginAction(payload);
  };

  const showPasswordMethod = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const showConfirmPasswordMethod = (e) => {
    e.preventDefault();
    setConfirmPassword(!showConfirmPassword);
  };

  const handleCountryChange = (option) => {
    setUserData({
      ...userData,
      countryName: option.label,
    });
  };

  useEffect(() => {
    props.getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.checkout.getCountries) {
      const countryArray = props.checkout.countries;
      const countryList = Object.keys(countryArray).map((item) => ({
        label: countryArray[item].name,
        value: countryArray[item].name,
      }));
      setCountriesList(countryList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkout.getCountries]);

  useEffect(() => {
    if (props.errors?.message) {
      swal({
        text: props.errors.message,
        icon: "error",
        button: "OK",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errors]);

  useEffect(() => {
    if (props.login.registerActionAuth) {
      swal({
        text: props.login.registerActionMessage,
        icon: frontLoader,
        timer: 4000,
        button: "OK",
      });
      setTimeout(() => {
        login({
          email: userData.email,
          password: userData.password,
        });
      }, 4000);
      setUserData({
        customername: "",
        email: "",
        password: "",
        confirmPassword: "",
        countryName: "",
        cityName: "",
        street: "",
        zipCode: "",
        mobile: "",
        state: "",
        companyType: true,
        companyURL: "",
        companyVat: "",
      });
      props.beforeLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.login.registerActionAuth]);

  useEffect(() => {
    if (props.login.loginActionAuth) {
      props.beforeLogin();
      history(`/${lang}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.login.loginActionAuth]);

  const { customername } = ENV.getUserKeys();

  if (customername) {
    history("/");
    return "";
  } else {
    const customStyles = {
      control: (base) => ({
        ...base,
      }),
    };

    return (
      <section className="wrapper">
        <Container fluid>
          <Form onSubmit={(e) => register(e)}>
            <Row className="align-items-center">
              <Col lg="4" md="4" xs="12" className="px-0">
                <div className="form-side-display d-flex justify-content-center align-items-center">
                  <Link to={`/${lang}`} className="d-inline-block">
                    <img src={LoginImg} alt="logo" className="img-fluid" />
                  </Link>
                </div>
              </Col>
              <Col lg="8" md="8" xs="12" className="px-0 ">
                <div className="login-main new-logged-des">
                  <div className="login-main-child">
                    <h6>{translations["Hello User"]}</h6>
                    <h2>{translations["Register As New Reseller"]}</h2>
                    <form className="login-form-main">
                      <div className="mb-3 login-form-input">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`${translations["Name"]}  ${translations["(Required)"]}`}
                          id="exampleInputText1"
                          value={userData.customername}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              customername: e.target.value,
                            })
                          }
                          onKeyUp={(e) => removeSpaces(e, nameRef)}
                          ref={nameRef}
                          autoFocus={true}
                        />
                        {msg.customername && (
                          <small className="error">{msg.customername}</small>
                        )}
                      </div>
                      <div className="mb-3 login-form-input">
                        <input
                          type="email"
                          className="form-control"
                          placeholder={`${translations["Email"]} ${translations["(Required)"]}`}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={userData.email}
                          onChange={(e) =>
                            setUserData({ ...userData, email: e.target.value })
                          }
                          ref={emailRef}
                          onKeyUp={(e) => removeSpaces(e, emailRef)}
                        />
                        {msg.email && (
                          <small className="error">{msg.email}</small>
                        )}
                      </div>
                      <div className="mb-3 login-form-input">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`${translations["Mobile"]} ${translations["(Required)"]}`}
                          value={userData.mobile}
                          onChange={(e) =>
                            setUserData({ ...userData, mobile: e.target.value })
                          }
                          ref={mobileRef}
                          onKeyUp={(e) => removeSpaces(e, mobileRef)}
                        />
                        {msg.mobile && (
                          <small className="error">{msg.mobile}</small>
                        )}
                      </div>
                      <div className="mb-3 login-form-input">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`${translations["State"]} ${translations["(Optional)"]}`}
                          value={userData.state}
                          onChange={(e) =>
                            setUserData({ ...userData, state: e.target.value })
                          }
                          ref={stateRef}
                          onKeyUp={(e) => removeSpaces(e, stateRef)}
                        />
                      </div>
                      <div className="mb-3 login-form-input">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`${translations["Street"]} ${translations["(Required)"]}`}
                          value={userData.street}
                          onChange={(e) =>
                            setUserData({ ...userData, street: e.target.value })
                          }
                          ref={streetRef}
                          onKeyUp={(e) => removeSpaces(e, streetRef)}
                        />
                        {msg.street && (
                          <small className="error">{msg.street}</small>
                        )}
                      </div>
                      <div className="mb-3 login-form-input">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`${translations["Zip Code"]} ${translations["(Required)"]}`}
                          value={userData.zipCode}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              zipCode: e.target.value,
                            })
                          }
                          ref={zipCodeRef}
                          onKeyUp={(e) => removeSpaces(e, zipCodeRef)}
                        />
                        {msg.zipCode && (
                          <small className="error">{msg.zipCode}</small>
                        )}
                      </div>
                      <div className="mb-3 login-form-input">
                        <Select
                          options={countriesList}
                          placeholder={translations["Select Country"]}
                          onChange={(option) => handleCountryChange(option)}
                          value={countriesList?.filter(
                            (option) => option.value === userData.countryName,
                          )}
                          styles={customStyles}
                        />
                        {msg.countryName && (
                          <small className="error">{msg.countryName}</small>
                        )}
                      </div>
                      <div className="mb-3 login-form-input">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`${translations["City"]} ${translations["(Required)"]}`}
                          value={userData.cityName}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              cityName: e.target.value,
                            })
                          }
                          ref={cityNameRef}
                          onKeyUp={(e) => removeSpaces(e, cityNameRef)}
                        />
                        {msg.cityName && (
                          <small className="error">{msg.cityName}</small>
                        )}
                      </div>
                      <div className="mb-3 login-form-input">
                        <div className="voucher-w-50"></div>
                      </div>
                      {userData.companyType && (
                        <>
                          <div className="mb-3 login-form-input">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`${translations["Company VAT"]} ${translations["(Required)"]}`}
                              value={userData.companyVat}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  companyVat: e.target.value,
                                })
                              }
                            />
                            {msg.companyVat && (
                              <small className="error">{msg.companyVat}</small>
                            )}
                          </div>
                          <div className="mb-3 login-form-input">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`${translations["Company"]} ${translations["(Required)"]}`}
                              value={userData.companyName}
                              onChange={(e) =>
                                setUserData({
                                  ...userData,
                                  companyName: e.target.value,
                                })
                              }
                              ref={companyRef}
                              onKeyUp={(e) => removeSpaces(e, companyRef)}
                            />
                            {msg.companyName && (
                              <small className="error">{msg.companyName}</small>
                            )}
                          </div>
                          <div className="mb-3 login-form-input">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`https://company.org/${translations["(Optional)"]}`}
                              value={userData.companyURL}
                              onChange={(e) => {
                                setUserData({
                                  ...userData,
                                  companyURL: e.target.value,
                                });
                              }}
                            />
                            {msg.companyURL && (
                              <small className="error">{msg.companyURL}</small>
                            )}
                          </div>
                        </>
                      )}
                      <div className="mb-3 login-form-input form-password-eye-box">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder={`${translations["Password"]}  ${translations["(Required)"]}`}
                          id="exampleInputPassword1"
                          value={userData.password}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              password: e.target.value,
                            })
                          }
                        />
                        <button
                          onClick={(e) => showPasswordMethod(e)}
                          className="form-password-eye"
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEye : faEyeSlash}
                          />
                        </button>
                        {msg.password && (
                          <small className="error">{msg.password}</small>
                        )}
                      </div>
                      <div className="mb-3 login-form-input form-password-eye-box">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          className="form-control"
                          placeholder={`${translations["Re type Password"]} ${translations["(Required)"]}`}
                          id="exampleInputConfirmPassword1"
                          value={userData.confirmPassword}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              confirmPassword: e.target.value,
                            })
                          }
                        />
                        <button
                          onClick={(e) => showConfirmPasswordMethod(e)}
                          className="form-password-eye"
                        >
                          <FontAwesomeIcon
                            icon={showConfirmPassword ? faEye : faEyeSlash}
                          />
                        </button>
                        {msg.confirmPassword && (
                          <small className="error">{msg.confirmPassword}</small>
                        )}
                      </div>
                      <div className="form-floating mb-3 login-form-input"></div>
                      <div className="row align-items-center recaptcha-btn-wrap ">
                        <div className="col-lg-12 col-md-12">
                          <div className="contact-us-main-btn">
                            <button
                              onClick={(e) => register(e)}
                              type="submit"
                              className="main-btn"
                            >
                              {translations["Register"]}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Link to={`/login/${lang}`} id="register-new-user">
                          {translations["Already Have a Customer Account"]}
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
    );
  }
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  login: state.login,
  checkout: state.checkout,
});

export default connect(mapStateToProps, {
  beforeLogin,
  registerAction,
  getCountries,
  loginAction,
})(Register);
