import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LocalizedStrings from "react-localization";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { getVATforCountry } from "../siteSetting/siteSetting.action";
import { ENV } from "../../config/config";
import { currencyFormat } from "../../util/function";
import { beforeCart, validateCoupon, addToUserCart } from "./cart.action";
import localizations from "../../localization";
import { useLanguage } from "../../context/language";
import { _localStorage } from "../../classes/_localStorage"; // Import the middleware
import productImg2 from "../../asset/image/products-device.png";
import "./Cart.css";

const Cart = (props) => {
  const userData = ENV.getUserKeys();
  const { lang } = useLanguage();

  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const [vatPercentage, setVatPercentage] = useState(
    props.siteSetting.getCountry
      ? props.siteSetting.vat
      : props.siteSetting.getSettings
        ? props.siteSetting.settings.vatPercentage
        : 19,
  );
  const [vatLabel, setVatLabel] = useState("VAT");
  const [inValidCoupon, setInValidCoupon] = useState(false);
  const currency = { symbol: "€", code: "EUR" };
  const [cartTotals, setCartTotals] = useState(
    JSON.parse(_localStorage.getItem("cartTotal"))
      ? JSON.parse(_localStorage.getItem("cartTotal"))
      : {
          subTotal: 0,
          discount: 0,
          tax: 0,
          total: 0,
        },
  );
  const [couponCodeMsg, setCouponCodeMsg] = useState(false);
  const [couponCode, setCouponCode] = useState(
    JSON.parse(localStorage.getItem("coupon"))
      ? JSON.parse(localStorage.getItem("coupon")).promotionCode
      : "",
  );
  const [coupon, setCoupon] = useState(
    JSON.parse(localStorage.getItem("coupon"))
      ? JSON.parse(localStorage.getItem("coupon"))
      : {},
  );
  const [cart, setCart] = useState(
    JSON.parse(_localStorage.getItem("cart"))
      ? JSON.parse(_localStorage.getItem("cart"))
      : [],
  );
  const [firststate, setFirststate] = useState(true);
  const [applyCouponPressed, setApplyCouponPressed] = useState(false);

  const incrementQuantity = (index) => {
    const localCart = cart;
    const cartQuantity = localCart[index].quantity + 1;
    if (cartQuantity <= localCart[index]?.totalLicenses) {
      localCart[index].quantity += 1;
      _localStorage.setItem("cart", JSON.stringify(localCart));

      updateCart();
      setCart([...localCart]);
    } else {
      swal({
        text: translations["License for this variant will be available soon"],
        icon: "warning",
        button: "OK!",
      });
    }
  };
  const bulkIncrementOrDecrement = (index, bulkQuantity) => {
    const localCart = cart;
    if (isNaN(bulkQuantity)) {
      return;
    }
    const cartQuantity = bulkQuantity;
    if (cartQuantity <= localCart[index]?.totalLicenses) {
      localCart[index].quantity = bulkQuantity;
      if (localCart[index].quantity === 0) {
        localCart.splice(index, 1);
      }
      _localStorage.setItem("cart", JSON.stringify(localCart));
      updateCart();
      setCart([...localCart]);
    } else {
      swal({
        text: translations["License for this variant will be available soon"],
        icon: "warning",
        button: "OK!",
      });
    }
  };
  const decrementQuantity = (index) => {
    const localCart = cart;
    localCart[index].quantity -= 1;
    if (localCart[index].quantity === 0) {
      localCart.splice(index, 1);
    }
    _localStorage.setItem("cart", JSON.stringify(localCart));
    updateCart();
    setCart([...localCart]);
  };
  const removeProduct = (index) => {
    const localCart = cart;
    localCart[index].quantity = 0;
    if (localCart[index].quantity === 0) {
      localCart.splice(index, 1);
    }
    _localStorage.setItem("cart", JSON.stringify(localCart));
    updateCart();
    setCart([...localCart]);
  };
  const applyCoupon = () => {
    if (!couponCode) {
      setInValidCoupon(false);
      setCouponCodeMsg(true);
    }
    if (couponCode) {
      setCouponCodeMsg(false);
      props.beforeCart();
      setApplyCouponPressed(true);
      props.validateCoupon({
        promotionCode: couponCode,
        customerId: userData._id,
      });
    }
  };
  const applyCouponToCart = () => {
    if (!inValidCoupon) {
      if (coupon && coupon.rules) {
        const localCart = cart;
        localCart.map((item) => {
          let discountPercentage = 0;
          let discountAmount = 0;
          let discountType = 0;
          if (coupon) {
            coupon?.rules?.map((rule) => {
              if (rule.minQty <= item.quantity || rule.minQty === undefined) {
                const startDate = new Date(rule.startDate);
                const endDate = new Date(rule.endDate);
                const currentDate = new Date();
                // Compare the dates
                //1=Fixed 0=percentage
                if (currentDate >= startDate && currentDate <= endDate) {
                  // eslint-disable-next-line default-case
                  switch (true) {
                    case rule.type === 1: // If the rule is applicable on all products
                      if (rule.discountType === 0) {
                        discountPercentage = rule.percentage;
                        discountAmount =
                          (item.quantity *
                            item.salesPrice *
                            discountPercentage) /
                          100;
                        discountType = rule.discountType;
                      } else {
                        discountAmount = rule.percentage;
                        discountType = rule.discountType;
                      }
                      return rule;
                    case rule.type === 2: // If the rule is applicable on specific product
                      if (item.productId === rule.productId) {
                        if (rule.discountType === 0) {
                          discountPercentage = rule.percentage;
                          discountAmount =
                            (item.quantity *
                              item.salesPrice *
                              discountPercentage) /
                            100;
                          discountType = rule.discountType;
                        } else {
                          discountAmount = rule.percentage;
                          discountType = rule.discountType;
                        }
                        return rule;
                      }
                      break;
                    case rule.type === 3: // If the rule is applicable on specific product and variation
                      if (
                        item.productId === rule.productId &&
                        item.variationId === rule.productVariationId
                      ) {
                        if (rule.discountType === 0) {
                          discountPercentage = rule.percentage;
                          discountAmount =
                            (item.quantity *
                              item.salesPrice *
                              discountPercentage) /
                            100;
                          discountType = rule.discountType;
                        } else {
                          discountAmount = rule.percentage;
                          discountType = rule.discountType;
                        }
                        return rule;
                      }
                  }
                }
              }
              return rule;
            });
          }
          item.discountType = discountType;
          item.discountPercentage = discountPercentage;
          item.discountAmount = discountAmount;
          return item;
        });
        const checkDiscountApplied = localCart.filter(
          (c) => c.discountAmount > 0,
        );
        if (applyCouponPressed) {
          if (checkDiscountApplied.length > 0) {
            if (localCart.length === checkDiscountApplied.length) {
              swal({
                text: translations["Coupon Applied on all products in cart"],
                icon: "success",
                button: "OK!",
              });
            } else {
              swal({
                text: translations[
                  "Coupon Applied, some items did not comply on the coupon"
                ],
                icon: "warning",
                button: "OK!",
              });
            }
          } else {
            swal({
              text: translations["None of the cart items comply on the coupon"],
              icon: "warning",
              button: "OK!",
            });
          }
          setApplyCouponPressed(false);
        }
        _localStorage.setItem("cart", JSON.stringify(localCart));
        setCart([...localCart]);
      }
    } else {
      if (applyCouponPressed) {
        swal({
          text: translations["Coupon is invalid"],
          icon: "error",
          button: "OK!",
        });
        setApplyCouponPressed(false);
      }
    }
  };

  const resetCoupon = () => {
    setCoupon({});
    localStorage.removeItem("coupon");
    let localCart = cart;
    localCart = localCart.map((item, cartIndex) => {
      delete item.discountPercentage;
      delete item.discountAmount;
      return item;
    });
    _localStorage.setItem("cart", JSON.stringify(localCart));
    setCart([...localCart]);
  };

  const updateCart = () => {
    applyCouponToCart();
    let subTotal = 0;
    let discount = 0;
    let tax = 0;
    let total = 0;

    cart.map((item) => {
      const subTotal_ = item.quantity * item.salesPrice; //	SalesPrice * Quantity
      subTotal += subTotal_;
      let taxAmount = 0;
      let discountedPrice = 0;
      // eslint-disable-next-line default-case
      if (item.discountPercentage > 0) {
        const discountPercentage = item.discountPercentage
          ? item.discountPercentage
          : 0; // DiscountPercentage
        discountedPrice = subTotal_ - (subTotal_ * discountPercentage) / 100; // (SalesPrice * Quantity) - Discount
        taxAmount = (discountedPrice * vatPercentage) / 100;

        discount += (subTotal_ * discountPercentage) / 100;
        item.price = discountedPrice + taxAmount;
        tax += taxAmount;
      } else if (item.discountAmount > 0) {
        const discountAmount = item.discountAmount ? item.discountAmount : 0; // discountAmount
        discountedPrice = subTotal_ - discountAmount; // (SalesPrice * Quantity) - Discount
        taxAmount = (vatPercentage / 100) * discountedPrice;

        discount += discountAmount;
        item.price = discountedPrice + taxAmount;
        tax += taxAmount;
      } else {
        taxAmount = (vatPercentage / 100) * subTotal_;
        tax += taxAmount;
      }
      return item;
    });
    total = subTotal - discount + tax;
    setCartTotals({
      subTotal: subTotal,
      discount: discount,
      tax: tax,
      total: total,
    });
    _localStorage.setItem(
      "cartTotal",
      JSON.stringify({
        subTotal: subTotal,
        discount: discount,
        tax: tax,
        total: total,
      }),
    );
    if (userData?._id) {
      //If user is logged in
      cart.map((item, index) => {
        cart[index].unitSalesPrice = cart[index].salesPrice;
        cart[index].totalPrice = cart[index].salesPrice * cart[index].quantity;
        return item;
      });
      const cartParams = {
        userId: userData._id,
        isCheckout: false,
        items: cart,
      };
      if (firststate) {
        setFirststate(false);
      } else {
        props.addToUserCart(cartParams);
      }
    }
  };

  useEffect(() => {
    setCouponCodeMsg(false);
    resetCoupon();
    props.beforeCart();
    setCouponCode("");
    props.validateCoupon({ customerId: userData._id });
  }, []);

  useEffect(() => {
    if (props.siteSetting.getSettings) {
      if (userData?.addresses && userData?.addresses?.length > 0) {
        props.getVATforCountry(userData?.addresses[0]?.country);
      } else {
        setVatPercentage(props.siteSetting.settings.vatPercentage);
        setVatLabel("VAT");
      }
    }
  }, [props.siteSetting.getSettings]);

  useEffect(() => {
    if (props.siteSetting.getCountry) {
      setVatLabel(props.siteSetting.vat_label);
      setVatPercentage(props.siteSetting.vat);
    }
  }, [props.siteSetting.getCountry]);

  useEffect(() => {
    if (props.cart.validateCouponAuth) {
      if (
        !props.cart.coupon.priceList?.customer ||
        (props.cart.coupon.priceList?.customer &&
          props.cart.coupon.priceList?.customer?.includes(userData._id))
      ) {
        setCoupon(props.cart.coupon.priceList);
        setInValidCoupon(false);
      } else {
        setCoupon({});
        if (props.cart.coupon?.show) {
          setInValidCoupon(false);
        } else {
          setInValidCoupon(true);
        }
      }
      props.beforeCart();
    }
  }, [props.cart.validateCouponAuth]);

  useEffect(() => {
    if (props.cart.inValidateCouponAuth) {
      setCoupon({});
      if (applyCouponPressed) {
        swal({
          text: translations["Coupon is invalid"],
          icon: "error",
          button: "OK!",
        });
      }
      setInValidCoupon(true);
      resetCoupon();
      localStorage.setItem("coupon", JSON.stringify({}));
      props.beforeCart();
    }
  }, [props.cart.inValidateCouponAuth]);

  useEffect(() => {
    if (coupon && coupon.rules) {
      updateCart();
      localStorage.setItem("coupon", JSON.stringify(coupon));
    } else {
      localStorage.setItem("coupon", JSON.stringify({}));
    }
  }, [coupon]);

  useEffect(() => {
    updateCart();
  }, [coupon]);

  return (
    <section className="cart-wrapper">
      <Container>
        <Row>
          <Col lg="12" xs="12" className="mb-5">
            <div className="card card-info">
              <div className="card-body">
                <div className="cart-product-box">
                  {cart && cart.length > 0 ? (
                    cart.map((item, index) => {
                      return (
                        <Row className="cart_row" key={index}>
                          <Col lg="12" md="12" sm="12">
                            <Row>
                              <Col lg="2" md="2" sm="2">
                                <div className="product-img-wrap">
                                  <Link
                                    target="_blank"
                                    title="View product"
                                    to="#."
                                  >
                                    <img
                                      className="img-responsive"
                                      src={
                                        item.image ? item.image : productImg2
                                      }
                                      alt=""
                                    />
                                  </Link>
                                </div>
                              </Col>
                              <Col lg="8" md="8" sm="8">
                                <p className="product-name ml-2 mb-1 checkout-p">
                                  <strong title="Product name">
                                    {item.name} {item.variationName}
                                  </strong>
                                </p>
                              </Col>
                              <Col lg="2" md="2" sm="2">
                                <div className="dlt-btn-wrap">
                                  <button
                                    type="button"
                                    className="btn btn-link btn-xs"
                                    onClick={() => removeProduct(index)}
                                  >
                                    <FontAwesomeIcon icon={faXmark} />
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg="12" md="12" sm="12">
                            <div className="quantity-price-wrapper mt-3 mb-3">
                              <div className="quantity-div cart-quilty-incremnt">
                                <button
                                  type="button"
                                  className="decrement"
                                  id=""
                                  title="Decrease quantity"
                                  onClick={() => decrementQuantity(index)}
                                >
                                  <FontAwesomeIcon icon={faMinus} />
                                </button>
                                <input
                                  title="Quantity"
                                  type="text"
                                  maxLength="4"
                                  max="9999"
                                  size="1"
                                  min={0}
                                  value={item.quantity}
                                  onKeyPress={(event) =>
                                    (event.charCode !== 8 &&
                                      event.charCode === 0) ||
                                    (event.charCode >= 48 &&
                                      event.charCode <= 57)
                                  }
                                  className="qty-input form-control"
                                  onChange={(event) => {
                                    bulkIncrementOrDecrement(
                                      index,
                                      event.target.value !== ""
                                        ? parseInt(event.target.value)
                                        : 1,
                                    );
                                  }}
                                />
                                <button
                                  type="button"
                                  className="increment"
                                  id=""
                                  title="Increase quantity"
                                  onClick={() => incrementQuantity(index)}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </button>
                              </div>
                              <div className="text-right">
                                <h6 title="Unit price">
                                  <strong>
                                    {currencyFormat(
                                      item.salesPrice,
                                      currency.code,
                                    )}{" "}
                                    &nbsp;
                                    <span className="text-muted">x</span>&nbsp;
                                    {item.quantity}
                                  </strong>
                                </h6>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      );
                    })
                  ) : (
                    <Row className="cart_row">
                      <Col lg="12" md="12" sm="12">
                        {localStorage.getItem("attivitaEncryptedUser") ? (
                          <p className="product-name text-center">
                            {translations["Nothing in the cart. Click"]}{" "}
                            <Link to={`/shop/${lang}`}>
                              {translations["here"]}
                            </Link>{" "}
                            {translations["to add product to cart"]}
                          </p>
                        ) : (
                          <p className="text-center">
                            {
                              translations[
                                "You are not logged in to the system. Kindly"
                              ]
                            }{" "}
                            <Link to={`/login/${lang}`}>
                              {translations["login"]}
                            </Link>{" "}
                            {translations["to continue"]}
                          </p>
                        )}
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
              <div className="card-footer checkout-card-footer">
                <Row id="couponArea">
                  <Col md="12" xs="12">
                    <h6 className="">{translations["Have a coupon"]}</h6>
                  </Col>
                  <Col md="12" xs="12">
                    <input
                      type="text"
                      className="cart-form-control mb-2"
                      value={couponCode}
                      onChange={(event) => setCouponCode(event.target.value)}
                      id="coupon-code-text"
                      placeholder={translations["Enter Coupon Code"]}
                    />
                    {inValidCoupon && couponCode && (
                      <small className="error">
                        {" "}
                        {translations["Coupon is invalid"]}
                      </small>
                    )}
                    {couponCodeMsg && (
                      <small className="error">
                        {" "}
                        {translations["Coupon is required"]}
                      </small>
                    )}
                    <button
                      type="button"
                      className="btn btn-success cart-btn-block"
                      onClick={() => applyCoupon()}
                    >
                      {translations["Apply Coupon"]}
                    </button>
                  </Col>
                </Row>
                <Row className="text-center">
                  <Col lg="12" xs="12">
                    <h6 className="text-right cart-h6">
                      {translations["Sub Total"]}
                      <strong id="subtotal_price" className="ml-2">
                        {currencyFormat(cartTotals.subTotal, currency.code)}
                      </strong>
                    </h6>
                    <h6 className="text-right cart-h6">
                      {translations["Discount"]}
                      <strong id="subtotal_price" className="ml-2">
                        {currencyFormat(cartTotals.discount, currency.code)}
                      </strong>
                    </h6>
                    <h6 className="text-right cart-h6">
                      {vatLabel} {vatPercentage}%
                      <strong id="vat_total_price" className="ml-2">
                        {currencyFormat(cartTotals.tax, currency.code)}
                      </strong>
                    </h6>
                    <h6 className="text-right cart-h6">
                      {translations["Total"]}
                      <strong id="total_price" className="ml-2">
                        {currencyFormat(cartTotals.total, currency.code)}
                      </strong>
                    </h6>
                    {cart && cart.length > 0 && (
                      <div id="checkoutButton" className="mt-3">
                        <Link to={`/checkout/${lang}`}>
                          <button
                            type="button"
                            className="btn btn-success btn-block"
                          >
                            {translations["Checkout"]}
                          </button>
                        </Link>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  siteSetting: state.siteSetting,
  cart: state.cart,
});
export default connect(mapStateToProps, {
  beforeCart,
  validateCoupon,
  addToUserCart,
  getVATforCountry,
})(Cart);
