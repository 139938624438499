import {
  EMPTY_ERRORS,
  GET_ERRORS,
  BEFORE_DASHBOARD,
  GET_LISTING,
  GET_DASHBOARD_ORDER,
  GET_DATA,
} from "../../redux/types";
import { ENV } from "../../config/config";
import { handleResponse } from "../../util/helper";
import { getHttpRequest } from "../../asset/axios";

export const emptyError = () => {
  return {
    type: EMPTY_ERRORS,
  };
};

export const beforeDashboard = (data) => {
  return {
    type: BEFORE_DASHBOARD,
  };
};

export const getDashboardData =
  (userId, qs = "") =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}dashboard/get-dashboard-data?userId=${userId}`;
    if (qs) url += `&${qs}`;

    getHttpRequest(url)
      .then(handleResponse)
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_DATA,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      });
  };

export const getDashboardListing =
  (userId, type, qs = "") =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.url}dashboard/get-dashboard-listing?userId=${userId}&type=${type}`;
    if (qs) url += `&${qs}`;

    getHttpRequest(url)
      .then(handleResponse)
      .then((data) => {
        if (data.success) {
          dispatch({
            type: GET_LISTING,
            payload: data,
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      });
  };
export const getOrder = (orderId) => (dispatch) => {
  dispatch(emptyError());
  const url = `${ENV.url}dashboard/get-order/${orderId}`;
  getHttpRequest(url)
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_DASHBOARD_ORDER,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};
