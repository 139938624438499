import {
  EMPTY_ERRORS,
  GET_ERRORS,
  GET_SETTINGS,
  GET_COUNTRY_DATA,
  BEFORE_SETIINGS,
} from "../../redux/types";
import { ENV } from "../../config/config";
import { handleResponse } from "../../util/helper";
import { getHttpRequest, putHttpRequest } from "../../asset/axios";

export const emptyError = () => {
  return {
    type: EMPTY_ERRORS,
  };
};

export const beforeSettings = (data) => {
  return {
    type: BEFORE_SETIINGS,
  };
};

export const getSettings = () => (dispatch) => {
  dispatch(emptyError());

  getHttpRequest(`${ENV.url}setting/get`)
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_SETTINGS,
          payload: data.settings,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};

export const getVATforCountry = (countryName) => (dispatch) => {
  dispatch(emptyError());
  getHttpRequest(`${ENV.url}user/get-vat-for-country/${countryName}`)
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_COUNTRY_DATA,
          payload: data.VAT,
          vat_label: data.vat_label,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};

export const updateLanguage = (payload) => (dispatch) => {
  putHttpRequest(`${ENV.url}auth/update-language`, JSON.stringify(payload))
    .then((res) => res.json())
    .then((data) => {});
};
