import React, { useRef, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import LocalizedStrings from "react-localization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { beforeProducts, searchProducts } from "../product/product.action";
import { currencyFormat } from "../../util/function";
import localizations from "../../localization";
import { useLanguage } from "../../context/language";
import "./Search.css";

const Search = (props) => {
  const { lang } = useLanguage();
  const searchDropdown = useRef(null);
  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const [closeSearch, setCloseSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sort, setSort] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [currency, setCurrency] = useState({ symbol: "€", code: "EUR" }); // eslint-disable-line no-unused-vars

  const closeOpenSearch = (e) => {
    if (
      searchDropdown.current &&
      closeSearch === false &&
      !searchDropdown.current.contains(e.target)
    ) {
      setCloseSearch(false);
    }
  };

  const searchProducts = () => {
    if (searchQuery || sort) props.searchProducts(searchQuery, sort);
    setSearchResults([]);
  };

  useEffect(() => {
    if (props.product.getsearchProducts) {
      setSearchResults(props.product.products);
      props.changeSelected(props.product.products);
      props.beforeProducts();
    }
  }, [props.product.getsearchProducts]);

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenSearch);
  }, []);

  useEffect(() => {
    if (props.product.getsearchProducts) {
      if (props.product.products.length > 0) {
        setCloseSearch(true);
      }
    }
  }, [props.product.getsearchProducts]);

  useEffect(() => {
    searchProducts();
  }, [searchQuery]);

  return (
    <Container>
      <div className="shop-searching">
        <div className="shop-search-wrapper m-auto">
          <div className="shop-searching-box wrapInput">
            <div className="inputBg">
              <div id="search-field">
                <input
                  type="search"
                  className="form-control"
                  onInput={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  placeholder={translations["Search"]}
                />
              </div>
              <div id="search-icon">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </div>
            </div>
            <div>
              <div className="shop-search-dropdown">
                <Dropdown
                  onSelect={(selectedSort) => {
                    setSort(selectedSort);
                    props.setSort(selectedSort);
                  }}
                >
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                    <span>
                      {[1, "1"].includes(sort)
                        ? translations["Price: Asc"]
                        : ""}
                      {[2, "2"].includes(sort)
                        ? translations["Price: Desc"]
                        : ""}
                      {[3, "3"].includes(sort) ? translations["Name: Asc"] : ""}
                      {[4, "4"].includes(sort)
                        ? translations["Name: Desc"]
                        : ""}
                      {!sort ? translations["Sort by"] : ""}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="customMenu">
                    <Dropdown.Item eventKey={1}>Price: Asc</Dropdown.Item>
                    <Dropdown.Item eventKey={2}>Price: Desc</Dropdown.Item>
                    <Dropdown.Item eventKey={3}>Name: Asc</Dropdown.Item>
                    <Dropdown.Item eventKey={4}>Name: Desc</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {searchResults && searchResults.length > 0 && closeSearch === true ? (
            <div id="search-result" ref={searchDropdown}>
              <ul>
                {searchResults.map((searchResult, index) => {
                  return (
                    <li>
                      <Link
                        to={`/product-detail/${searchResult._id}/${localStorage.getItem("language")}`}
                        className="link-unstyled col-lg-12"
                      >
                        {searchResult.name}
                        <span className="float-lg-end">
                          {" "}
                          Starting from{" "}
                          {currencyFormat(
                            searchResult.initialPrice,
                            currency.code,
                          )}
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  product: state.product,
});

export default connect(mapStateToProps, { beforeProducts, searchProducts })(
  Search,
);
