import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LocalizedStrings from "react-localization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faPinterest } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faSkype } from "@fortawesome/free-brands-svg-icons";
import { getMenu, beforeCMS } from "../cms/cms.action";
import localizations from "../../localization";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useLanguage } from "../../context/language";
import { ENV } from "../../config/config";
import { formatURL } from "../../util/helper";
import logoImg from "../../asset/image/footer-logo.svg";
import "./Footer.css";

const Footer = (props) => {
  const { _id, customername, isVendor } = ENV.getUserKeys();
  const [setting, setSetting] = useState();
  const [cookieOk, setcookieOk] = useState(
    localStorage.getItem("cookieAllowed"),
  );
  const [footerMenus, setFooterMenus] = useState([]);
  const {
    apiUrls: { cookieUrls },
  } = ENV;

  const setCookieAllowed = () => {
    setcookieOk(true);
    localStorage.setItem("cookieAllowed", "true");
  };

  const { lang } = useLanguage();
  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  useEffect(() => {
    props.getMenu("footer");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.cms.getMenu) {
      setFooterMenus(props.cms.menus);
      props.beforeCMS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cms.getMenu]);

  useEffect(() => {
    if (props.siteSetting.getSettings) {
      setSetting(props.siteSetting.settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.siteSetting.getSettings]);

  return (
    <>
      {!cookieOk ? (
        <div className="text-center cookiealert" role="alert">
          <b>{translations["Do you like cookies?"]}</b> 🍪{" "}
          {
            translations[
              "We use cookies to ensure you get the best experience on our website."
            ]
          }{" "}
          <a href={cookieUrls} target="_blank" rel="noreferrer">
            {translations["Learn more"]}
          </a>
          <button
            type="button"
            className="btn acceptcookies"
            onClick={() => setCookieAllowed()}
          >
            {translations["I agree"]}
          </button>
        </div>
      ) : (
        ""
      )}
      <footer id="footer">
        <div className="container-fluid">
          <div className="footer-content d-flex justify-content-center align-items-center flex-column">
            <strong className="footer-logo-img">
              <Link to={`/${lang}`}>
                <img src={logoImg} alt="logo" className="img-fluid" />
              </Link>
            </strong>
            <div className="footer-nav  text-center mb-3">
              <ul className="navbar-nav flex-sm-row">
                {footerMenus &&
                  footerMenus.length > 0 &&
                  footerMenus.map((menu, index) => (
                    <li className="nav-item" key={index}>
                      <Link
                        className="nav-link"
                        to={`/page/${menu.slug}/${lang}`}
                      >
                        {lang === "de" ? menu.germanTitle : menu.title}
                      </Link>
                    </li>
                  ))}
                {isVendor && (
                  <li className="nav-item">
                    <Link to={`/configuration/${lang}`} className={`nav-link`}>
                      {translations["API Configuration"]}
                    </Link>
                  </li>
                )}
              </ul>
              <a href={`mailto:${setting ? setting.email : ""}`}>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} /> :{" "}
                  <span>{setting ? setting.email : ""}</span>
                </p>
              </a>
              {setting?.skype && (
                <a href={`skype:${setting.skype}?chat`}>
                  <p>
                    <FontAwesomeIcon icon={faSkype} /> :{" "}
                    <span>Andreas Zurlo</span>
                  </p>
                </a>
              )}
            </div>
            <div className="footer-icons">
              <ul className="list-unstyled d-flex">
                {setting?.facebook && (
                  <li>
                    <a href={formatURL(setting?.facebook)} rel="noreferrer">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                )}
                {setting?.twitter && (
                  <li>
                    <a href={formatURL(setting?.twitter)} rel="noreferrer">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                )}
                {setting?.linkedin && (
                  <li>
                    <a href={formatURL(setting?.linkedin)} rel="noreferrer">
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </li>
                )}
                {setting?.pinterest && (
                  <li>
                    <a href={formatURL(setting?.pinterest)} rel="noreferrer">
                      <FontAwesomeIcon icon={faPinterest} />
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <p>Copyright © Attivita GmbH. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  siteSetting: state.siteSetting,
  cms: state.cms,
});
export default connect(mapStateToProps, { getMenu, beforeCMS })(Footer);
