import {
  EMPTY_ERRORS,
  GET_ERRORS,
  BEFORE_CONTACT,
  FORM_SUBMIT,
} from "../../redux/types";
import { ENV } from "../../config/config";
import { handleResponse } from "../../util/helper";
import { postHttpRequest } from "../../asset/axios";

export const emptyError = () => {
  return {
    type: EMPTY_ERRORS,
  };
};

export const beforeContact = (data) => {
  return {
    type: BEFORE_CONTACT,
  };
};

export const submitQuery = (body) => (dispatch) => {
  dispatch(emptyError());
  postHttpRequest(`${ENV.url}contact/submit`, JSON.stringify(body))
    .then(handleResponse)
    .then((data) => {
      if (data.success) {
        dispatch({
          type: FORM_SUBMIT,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((errors) => {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    });
};
