import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import LocalizedStrings from "react-localization";
import Modal from "react-bootstrap/Modal";
import {
  getProductsList,
  getManufacturersList,
  beforeProducts,
  getCategories,
} from "./product.action";
import { getVATforCountry } from "../siteSetting/siteSetting.action";
import { ENV } from "../../config/config";
import localizations from "../../localization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faRefresh,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { useLanguage } from "../../context/language";
import ProductCard from "./productCard";
import "react-loading-skeleton/dist/skeleton.css";
import "./Product.css";
/* eslint-disable jsx-a11y/anchor-is-valid */
const Product = (props) => {
  const user = ENV.getUserKeys();
  const { lang } = useLanguage();

  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    setIsActive((current) => !current);
  };
  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const [products, setProducts] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);

  const [manufacturerId, setManufacturerId] = useState(null);

  const [manufacturersLoader, setManufacturersLoader] = useState(false);
  const [productsLoader, setProductLoader] = useState(false);
  const [categoriesLoader, setCategoriesLoader] = useState(false);

  const [activeManufacturer, setActiveManufacturer] = useState("");

  const [show, setShow] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchManufacturers();
    fetchProducts();
    fetchCategories();
  }, []);

  const fetchProducts = () => {
    const resellers = ENV.getUserKeys();
    setProductLoader(true);
    const qsProduct = ENV.objectToQueryString({
      bestSeller: false,
      resellerId: resellers?._id,
      manufacturer: manufacturerId,
      categoryId,
      sort: props.sort,
    });
    props.getProductsList(qsProduct);
  };
  useEffect(() => {
    if (props.product.getproductsList) {
      setProducts(props.product.productsList.products);
      setProductLoader(false);
      props.beforeProducts();
    }
  }, [props.product.getproductsList]); // Products Fetched

  const fetchManufacturers = () => {
    const resellers = ENV.getUserKeys();
    const qsManufacturer = ENV.objectToQueryString({
      bestSeller: false,
      resellerId: resellers?._id,
      categoryId,
    });
    setManufacturersLoader(true);
    props.getManufacturersList(qsManufacturer);
  };
  useEffect(() => {
    if (props.product.getManufacturersList) {
      const { manufacturers } = props.product.manufacturers;
      setManufacturers(manufacturers);
      const params = new URLSearchParams(window.location.search);
      const manufacturerQueryId = params.get("manufacturerId");
      if (manufacturerQueryId) {
        setManufacturerId(manufacturerQueryId);
      } else {
        setActiveManufacturer("show-all");
      }
      setManufacturersLoader(false);
      props.beforeProducts();
    }
  }, [props.product.getManufacturersList]); // Manufacturers Fetched

  const fetchCategories = () => {
    const qsCategory = ENV.objectToQueryString({
      all: 1,
      page: 1,
      limit: 50,
      status: true,
      manufacturer: manufacturerId,
      isFront: true,
    });
    setCategoriesLoader(true);
    props.beforeProducts();
    props.getCategories(qsCategory);
  };
  useEffect(() => {
    if (props.product.getCategoriesAuth) {
      const { categories } = props.product.categories;
      setCategoriesLoader(false);
      setCategories(categories);
    }
  }, [props.product.getCategoriesAuth]); // Categories Fetched

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, [manufacturerId]); // Manufacturer  Selected
  useEffect(() => {
    fetchProducts();
    fetchManufacturers();
  }, [categoryId]); //  Category Selected
  useEffect(() => {
    fetchProducts();
  }, [props.sort]); //  Sorting Updated

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getDataByManufacturer = (manufacturerId_) => {
    setManufacturerId(manufacturerId_);
    setCategoryId(null);
  };
  const getDatabyCategory = (categoryId_) => {
    setCategoryId(categoryId_);
  };

  useEffect(() => {
    if (props.siteSetting.getSettings) {
      if (user?.addresses && user?.addresses?.length > 0) {
        props.getVATforCountry(user?.addresses[0]?.country);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.siteSetting.getSettings]);

  const resetPage = () => {
    setManufacturerId(null);
    setCategoryId(null);
    fetchManufacturers();
    fetchProducts();
    fetchCategories();
  };
  return (
    <section className="products-section custom-container home-product-sec">
      <Container fluid>
        <h2 className="text-center">{translations["Our Products Range"]}</h2>
        {activeManufacturer && (
          <Tab.Container defaultActiveKey={activeManufacturer}>
            <h3 className="vendor-head-sty">
              {translations["Choose from Vendors"]}
            </h3>
            <Row className="mb-5 vendor-row">
              <Col lg="12" md="12" sm="12">
                <Nav variant="pills" className="row flex-row">
                  {!manufacturersLoader ? (
                    manufacturers.length > 0 ? (
                      manufacturers.map((manufacturer, index) => {
                        return (
                          <Nav.Item
                            className="col-xl-2 col-lg-4 col-md-4 col-sm-4"
                            key={index}
                            onClick={() => {
                              getDataByManufacturer(manufacturer?._id);
                            }}
                          >
                            <Nav.Link
                              eventKey={manufacturer._id + "-event"}
                              className="link-unstyled  vendor-images px-0"
                            >
                              <div className="products-card d-flex justify-content-center align-items-center flex-column position-relative">
                                <div className="products-same-img vendor-img">
                                  <img
                                    src={manufacturer.image}
                                    className="img-fluid"
                                    alt={
                                      manufacturer.imageAltText
                                        ? manufacturer.imageAltText
                                        : manufacturer.name
                                    }
                                  />
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        );
                      })
                    ) : (
                      <p className="text-danger">
                        {
                          translations[
                            "Currently no vendor is available pertaining to your requirement. For further queries, please contact us."
                          ]
                        }
                      </p>
                    )
                  ) : (
                    <Fragment>
                      <Nav.Item className="col-lg-2">
                        <SkeletonTheme color="#202020" highlightColor="#7E9DF3">
                          <p>
                            <Skeleton count={3} />
                          </p>
                        </SkeletonTheme>
                      </Nav.Item>
                      <Nav.Item className="col-lg-2">
                        <SkeletonTheme color="#202020" highlightColor="#7E9DF3">
                          <p>
                            <Skeleton count={3} />
                          </p>
                        </SkeletonTheme>
                      </Nav.Item>
                    </Fragment>
                  )}
                </Nav>
              </Col>
            </Row>

            <h3 className="vendor-head-sty">
              {translations["Our Products Range"]}
            </h3>
            <div
              className={`category-product-wrapper mb-5 ${isActive ? "hide-class" : ""}`}
            >
              <div className="category-product-sidebar" onClick={handleClick}>
                <FontAwesomeIcon icon={faBars} className="toggler-bars" />
                <ul className="category-product-sidebar-list mt-3 position-relative add-scroll">
                  <FontAwesomeIcon
                    className="float-right refresh-icon"
                    onClick={() => {
                      resetPage();
                    }}
                    icon={faRefresh}
                  />
                  {categoriesLoader
                    ? [1, 2, 3, 4].map((k) => {
                        return (
                          <li key={k}>
                            <Fragment>
                              <Nav.Item className="col-lg-12">
                                <SkeletonTheme
                                  color="#202020"
                                  highlightColor="#7E9DF3"
                                >
                                  <p>
                                    <Skeleton count={1} />
                                  </p>
                                </SkeletonTheme>
                              </Nav.Item>
                            </Fragment>
                          </li>
                        );
                      })
                    : categories.map((category, k) => {
                        if (
                          manufacturerId &&
                          category?.manufacturers?.includes(manufacturerId)
                        ) {
                          return (
                            <li key={k}>
                              <a
                                className={
                                  categoryId === category?._id ? "active" : ""
                                }
                                onClick={(e) =>
                                  getDatabyCategory(category?._id)
                                }
                              >
                                {lang === "en"
                                  ? category?.titleEn
                                  : category?.titleDe}
                              </a>
                            </li>
                          );
                        } else if (!manufacturerId) {
                          return (
                            <li key={k}>
                              <a
                                className={
                                  categoryId === category?._id ? "active" : ""
                                }
                                onClick={(e) =>
                                  getDatabyCategory(category?._id)
                                }
                              >
                                {lang === "en"
                                  ? category?.titleEn
                                  : category?.titleDe}
                              </a>
                            </li>
                          );
                        } else {
                          return "";
                        }
                      })}
                </ul>
              </div>
              <div className="category-product-main-area">
                <Row className="mb-5">
                  <Col lg="12" md="12" sm="12">
                    {!productsLoader ? (
                      products.length > 0 ? (
                        <Row className="all_products_row">
                          {products.map((product, index_) => {
                            let allow_display = true;
                            if (
                              window.location.pathname.split("/")[1] === "" &&
                              index_ > 7
                            ) {
                              allow_display = false;
                              return "";
                            }
                            if (allow_display) {
                              const productLink =
                                "/product-detail/" + product._id + "/" + lang;
                              return (
                                <ProductCard
                                  productLink={productLink}
                                  product_={product}
                                  handleShow={handleShow}
                                  index={index_}
                                  key={index_}
                                />
                              );
                            }
                            return "";
                          })}
                        </Row>
                      ) : (
                        <p>No Product Avaliable</p>
                      )
                    ) : (
                      <Fragment>
                        <Nav.Item className="col-lg-2">
                          <SkeletonTheme
                            color="#202020"
                            highlightColor="#7E9DF3"
                          >
                            <p>
                              <Skeleton count={6} />
                            </p>
                          </SkeletonTheme>
                        </Nav.Item>
                        <Nav.Item className="col-lg-2">
                          <SkeletonTheme
                            color="#202020"
                            highlightColor="#7E9DF3"
                          >
                            <p>
                              <Skeleton count={6} />
                            </p>
                          </SkeletonTheme>
                        </Nav.Item>
                      </Fragment>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Tab.Container>
        )}
      </Container>
      <>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header
            closeButton
            className="user-auth-modal-header"
          ></Modal.Header>
          <Modal.Body className="user-auth-modal">
            {localStorage.getItem("attivitaEncryptedUser") ? (
              <>
                <div className="d-flex justify-content-center mb-4">
                  <FontAwesomeIcon icon={faWarning} className="warning-icon" />
                </div>
                {
                  translations[
                    "Your account is not active. Kindly contact admin to verify your account."
                  ]
                }
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center mb-4">
                  <FontAwesomeIcon icon={faWarning} className="warning-icon" />
                </div>
                {translations["here"]}{" "}
                <Link to={`/login/${lang}`}>{translations["login"]}</Link>{" "}
                {translations["go to login to see all product details"]}
              </>
            )}
          </Modal.Body>
        </Modal>
      </>
    </section>
  );
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  product: state.product,
  siteSetting: state.siteSetting,
});
export default connect(mapStateToProps, {
  beforeProducts,
  getProductsList,
  getVATforCountry,
  getCategories,
  getManufacturersList,
})(Product);
