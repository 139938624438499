import Axios from "./_instance";
let abortController = new AbortController();

export async function getHttpRequest(
  url,
  data,
  config = {},
  includeUserPlatform = false,
) {
  const defaultHeaders = {
    lang: localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "",
    "Content-Type": "application/json",
  };

  if (includeUserPlatform) {
    defaultHeaders["user-platform"] = 2;
  }

  const requestConfig = {
    signal: abortController.signal,
    headers: {
      ...defaultHeaders,
    },
    ...config,
  };
  return Axios.get(url, requestConfig);
}
export async function postHttpRequest(url, data, config = {}, content) {
  const defaultHeaders = {
    "Content-Type": content ? "multipart/form-data" : "application/json",
  };

  const requestConfig = {
    signal: abortController.signal,
    headers: {
      ...defaultHeaders,
    },
    ...config,
  };
  return Axios.post(url, data, requestConfig);
}
export async function putHttpRequest(url, data, config = {}) {
  const defaultHeaders = {
    "Content-Type": "application/json",
  };

  const requestConfig = {
    signal: abortController.signal,
    headers: {
      ...defaultHeaders,
    },
    ...config,
  };
  return Axios.put(url, data, requestConfig);
}
export function cancelOngoingHttpRequest() {
  abortController.abort();
  abortController = new AbortController();
}
