import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactDOM from "react-dom";
import TopHeader from "../header/topHeader";
import Footer from "../footer/Footer";
import { getPage, beforeCMS } from "./cms.action";
import ContactUs from "./contactUs/index";
import ContactUSTwo from "./contactUs2/index";
import { useLanguage } from "../../context/language";
import "react-loading-skeleton/dist/skeleton.css";

const CMS = (props) => {
  const myRef = useRef(null);
  const { lang } = useLanguage();
  const { slug } = useParams();
  const contentRef = useRef(null);

  const [pageContent, setPageContent] = useState(null);
  const [loader, setLoader] = useState(true);
  const [formKeyWord, setFormKeyWord] = useState(null);

  useEffect(() => {
    setLoader(true);
    props.getPage(window.location.pathname.split("/")[2]);
  }, []);

  useEffect(() => {
    setLoader(true);
    props.getPage(window.location.pathname.split("/")[2]);
  }, [slug]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (pageContent) {
      if (contentRef.current) {
        contentRef.current.innerHTML = pageContent;
      }
      if (formKeyWord === "contactus") {
        ReactDOM.render(
          <ContactUs />,
          document.getElementById("contactUsPlaceholder"),
        );
      } else if (formKeyWord && formKeyWord !== "contactus") {
        ReactDOM.render(
          <ContactUSTwo />,
          document.getElementById("contactUsPlaceholder"),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageContent]);
  const updateTargetAttribute = (htmlString) => {
    // Create a DOMParser
    const parser = new DOMParser();

    // Parse the HTML string into a DOM document
    const doc = parser.parseFromString(htmlString, "text/html");

    // Get all anchor tags in the document
    const anchorTags = doc.querySelectorAll("a");

    // Update the target attribute for each anchor tag
    anchorTags.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    // Serialize the updated DOM document back to an HTML string
    const updatedHtmlString = new XMLSerializer().serializeToString(doc);

    return updatedHtmlString;
  };
  useEffect(() => {
    const { cms } = props;
    const { getCms } = cms;
    if (getCms) {
      if (lang === "de") {
        const content = updateTargetAttribute(cms.content.germanDescription);
        setPageContent(content);
      } else {
        let content = cms.content?.description;
        content = updateTargetAttribute(content);

        if (content?.formKeyWord) {
          setFormKeyWord(cms.content.formKeyWord);
          const thisKey = cms.content.formKeyWord;
          const keyInContent = `\${{${thisKey}}}`;
          const regEx = new RegExp(
            keyInContent.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
            "gi",
          );
          if (thisKey === cms.content.formKeyWord) {
            content = content.replace(
              regEx,
              `<div id="contactUsPlaceholder"></div>`,
            );
          }
        }
        setPageContent(content);
      }
      props.beforeCMS();
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cms.getCms]);
  // Render the modified content
  return (
    <div>
      <TopHeader />
      {!loader ? (
        formKeyWord ? (
          <div id="main-header" ref={myRef}>
            <Row>{pageContent && <Col sm={12} ref={contentRef}></Col>}</Row>
            <Footer />
          </div>
        ) : (
          <div id="main-header" ref={myRef}>
            <section className="terms-of-use-content cms-data-protection">
              <Container fluid className="custom-container-cms">
                <Row>{pageContent && <Col sm={12} ref={contentRef}></Col>}</Row>
              </Container>
            </section>
            <Footer />
          </div>
        )
      ) : (
        <SkeletonTheme color="#202020" highlightColor="#7E9DF3">
          <Container>
            <Row>
              <Col className="mt-3">
                <Skeleton count={10} />
                <br />
                <Skeleton count={10} />
                <br />
                <Skeleton count={10} />
              </Col>
            </Row>
          </Container>
        </SkeletonTheme>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  cms: state.cms,
});
export default connect(mapStateToProps, { getPage, beforeCMS })(CMS);
