import React, { Fragment, useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import LocalizedStrings from "react-localization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";
import { predefinedContent } from "./content";
import { beforeSettings, getSettings } from "../siteSetting/siteSetting.action";
import { getHeaderContent, beforeContent } from "../content/content.action";
import { beforeCheckout, getLanguages } from "../checkout/checkout.action";
import Cart from "../cart/cart";
import localizations from "../../localization";
import { beforeCMS, getHeaderMenu } from "../cms/cms.action";
import closeMenuImg from "../../asset/image/x.svg";
import menuIcon from "../../asset/image/menu.svg";
import logoImg from "../../asset/image/logo.svg";
import { _localStorage } from "../../classes/_localStorage";

/* eslint-disable jsx-a11y/anchor-is-valid */

const Header = (props) => {
  const pathName = window.location.pathname;
  const history = useNavigate();

  const [languages, setLanguages] = useState("");
  const [click, setClick] = useState(false);
  const [logo, setLogo] = useState(); // eslint-disable-line no-unused-vars
  const [topHeadline, setTopHeadline] = useState([]);
  const [pageContent, setPageContent] = useState(predefinedContent); // eslint-disable-line no-unused-vars
  const [show, setShow] = useState(false);
  const [cart, setCart] = useState(
    JSON.parse(_localStorage.getItem("cart"))
      ? JSON.parse(_localStorage.getItem("cart"))
      : [],
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "de",
  );
  const [headerMenus, setHeaderMenus] = useState([]);
  const [lang, setLang] = useState(localStorage.getItem("language") ?? "de");

  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const setSiteLang = (code) => {
    localStorage.setItem("language", code);
    setSelectedLanguage(code);
    let theArr = window.location.pathname.split("/");
    theArr.pop();
    theArr = theArr.join("/");
    theArr = `${theArr}/${code}`;
    history(theArr);
    window.location.reload();
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClick = () => setClick(!click);

  useEffect(() => {
    props.getSettings();
    props.getLanguages();

    if (!props.content.getHeaderContent) {
      props.getHeaderContent("header");
      props.getHeaderMenu("header");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.cms.getHeaderMenu) {
      setHeaderMenus(props.cms.headerMenus);
      props.beforeCMS();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cms.getHeaderMenu]);

  useEffect(() => {
    if (
      localStorage.getItem("language") === "en" ||
      localStorage.getItem("language") === "de"
    ) {
      translations.setLanguage(localStorage.getItem("language"));
      setLang(localStorage.getItem("language"));
      setSelectedLanguage(localStorage.getItem("language"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    if (props.checkout.getLanguagesAuth) {
      const { languages } = props.checkout.languages;
      setLanguages(languages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkout.getLanguagesAuth]);

  useEffect(() => {
    setCart(
      JSON.parse(_localStorage.getItem("cart"))
        ? JSON.parse(_localStorage.getItem("cart"))
        : [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_localStorage.getItem("cart")]);

  useEffect(() => {
    if (props.siteSetting.getSettings) {
      setTopHeadline(props.siteSetting.settings);
      setLogo(props.siteSetting.settings.siteLogo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.siteSetting.getSettings]);

  useEffect(() => {
    if (props.content.getHeaderContent) {
      const { content } = props.content.headerContent;
      if (content) {
        setPageContent(content.content);
      }
      props.beforeContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content.getHeaderContent]);

  return (
    <Fragment>
      <div className="top-header">
        <ul>
          <li>
            <p>{lang === "de" ? topHeadline.leftDE : topHeadline.left}</p>
          </li>
          <li>
            <p>{lang === "de" ? topHeadline.middleDE : topHeadline.middle}</p>
          </li>
          <li>
            <p>{lang === "de" ? topHeadline.rightDE : topHeadline.right}</p>
          </li>
        </ul>
      </div>
      <header id="header" className="header">
        <nav className="navbar header-navbar navbar-expand-lg navbar-light">
          <Container fluid>
            <strong className="logo">
              <Link to={`/${lang}`}>
                <img
                  src={logoImg}
                  className="img-fluid"
                  alt="main-logo"
                  title="logo"
                />
              </Link>
            </strong>
            <div className={click ? "nav-options active" : "nav-options"}>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    to={`/${lang}`}
                    className={`nav-link ${pathName === `/${lang}` ? "active" : ""} `}
                  >
                    {translations["Home"]}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/shop/${lang}`}
                    className={`nav-link ${pathName === `/shop/${lang}` ? "active" : ""} `}
                  >
                    {translations["Shop"]}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/about-us/${lang}`}
                    className={`nav-link ${pathName === `/about-us/${lang}` ? "active" : ""} `}
                  >
                    {translations["About Us"]}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/contact-us/${lang}`}
                    className={`nav-link ${pathName === `/contact-us/${lang}` ? "active" : ""} `}
                  >
                    {translations["Contact Us"]}
                  </Link>
                </li>
                {headerMenus &&
                  headerMenus.length > 0 &&
                  headerMenus.map((menu, index) => (
                    <li className="nav-item" key={index}>
                      <Link
                        className="nav-link"
                        target="_blank"
                        to={`/page/${menu.slug}/${lang}`}
                      >
                        {lang === "de" ? menu.germanTitle : menu.title}
                      </Link>
                    </li>
                  ))}
                <div className="my-account hide-my-account">
                  <div className="my-account-filter">
                    <div id="country" className="d-flex align-items-center">
                      <Dropdown>
                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                          <div className="country-selection-img">
                            <img
                              src={`/${selectedLanguage}.svg`}
                              className="img-fluid"
                              alt="country"
                              title=""
                            />
                            <span>{selectedLanguage}</span>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <ul>
                            {languages &&
                              languages.map((lang) => (
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <Dropdown.Item>
                                      <span
                                        id="lang-name"
                                        title={lang.name}
                                        onClick={() =>
                                          setSiteLang(lang.iso_code)
                                        }
                                      >
                                        {lang.name}
                                      </span>
                                    </Dropdown.Item>
                                    <span id="flag-img">
                                      <img
                                        src={`/${lang.iso_code}.svg`}
                                        className="img-fluid"
                                        alt="country"
                                        title=""
                                      />
                                    </span>
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <Link to={`/register/${lang}`} className="my-account-seller">
                    <button type="button">
                      {translations["Become a Reseller"]}
                    </button>
                  </Link>
                  <Link to={`/login/${lang}`} className="my-account-login">
                    <button type="button" className="account-text-btn">
                      {translations["Login"]}
                    </button>
                    <button type="button" className="account-icon-btn">
                      <FontAwesomeIcon icon={faRightToBracket} />
                    </button>
                  </Link>
                </div>
              </ul>
            </div>
            <div className="my-account show-my-account">
              <div className="cart-icon-holder position-relative">
                <FontAwesomeIcon
                  icon={faBagShopping}
                  onClick={() => handleShow()}
                  title="Cart"
                />
                <span className="cart-qty count my-count">{cart.length}</span>
              </div>
              <div className="my-account-filter">
                <div id="country" className="d-flex align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="default" id="dropdown-basic">
                      <div className="country-selection-img">
                        <img
                          src={`/${selectedLanguage}.svg`}
                          className="img-fluid"
                          alt="country"
                          title=""
                        />
                        <span>{selectedLanguage}</span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <ul>
                        {languages &&
                          languages.map((lang) => (
                            <li>
                              <a className="dropdown-item" href="#">
                                <span
                                  id="lang-name"
                                  title={lang.name}
                                  onClick={() => setSiteLang(lang.iso_code)}
                                >
                                  {lang.name}
                                </span>
                                <span id="flag-img">
                                  <img
                                    src={`/${lang.iso_code}.svg`}
                                    className="img-fluid"
                                    alt="country"
                                    title=""
                                  />
                                </span>
                              </a>
                            </li>
                          ))}
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <Link to={`/login/${lang}`} className="my-account-login">
                <button type="button" className="account-text-btn">
                  {translations["Login"]}
                </button>
                <button type="button" className="account-icon-btn">
                  <FontAwesomeIcon icon={faRightToBracket} />
                </button>
              </Link>
              <Link to={`/register/${lang}`} className="my-account-seller">
                <button type="button">
                  {translations["Become a Reseller"]}
                </button>
              </Link>
            </div>
          </Container>
        </nav>
        <div className="login-menu-m">
          <div className="cart-icon-holder position-relative">
            <FontAwesomeIcon
              icon={faBagShopping}
              onClick={() => handleShow()}
              title="Cart"
            />
            <span className="cart-qty count my-count">{cart.length}</span>
          </div>
          <div className="mobile-menu" onClick={handleClick}>
            {click ? (
              <img src={closeMenuImg} className="menu-icon" alt="" />
            ) : (
              <img src={menuIcon} className="menu-icon" alt="" />
            )}
          </div>
        </div>
      </header>
      <Modal show={show} onHide={handleClose} className="cart-modal">
        <Modal.Header closeButton>
          <h4 className="mb-0">
            <span className="glyphicon glyphicon-shopping-cart"></span>{" "}
            <b>{translations["Cart"]}</b>
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Cart />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  siteSetting: state.siteSetting,
  checkout: state.checkout,
  content: state.content,
  cms: state.cms,
});
export default connect(mapStateToProps, {
  beforeSettings,
  getSettings,
  getHeaderContent,
  beforeContent,
  beforeCheckout,
  getLanguages,
  getHeaderMenu,
  beforeCMS,
})(Header);
