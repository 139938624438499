import { encrypt, decrypt } from "../util/encryption";

class __localStorage {
  setItem(key, value) {
    const encryptedValue = encrypt(JSON.stringify(value));
    localStorage.setItem(key, encryptedValue);
  }

  getItem(key) {
    const encryptedItem = localStorage.getItem(key);
    if (encryptedItem) {
      const decryptedItem = decrypt(encryptedItem);
      try {
        return JSON.parse(decryptedItem ? decryptedItem : null);
      } catch (e) {
        return decryptedItem == "" ? null : decryptedItem;
      }
    }
    return null;
  }

  removeItem(key) {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}

export const _localStorage = new __localStorage();
