import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LocalizedStrings from "react-localization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ENV } from "../../config/config";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";
import {
  beforeSettings,
  getSettings,
  updateLanguage,
} from "../siteSetting/siteSetting.action";
import {
  beforeCheckout,
  getUser,
  getLanguages,
} from "../checkout/checkout.action";
import { getAuthHeaderContent, beforeContent } from "../content/content.action";
import localizations from "../../localization";
import { getMenu, beforeCMS, getHeaderMenu } from "../cms/cms.action";
import Cart from "../cart/cart";
import { useLanguage } from "../../context/language";
import { _localStorage } from "../../classes/_localStorage";
import closeMenuImg from "../../asset/image/x.svg";
import menuIcon from "../../asset/image/menu.svg";
import logoImg from "../../asset/image/logo.svg";
import userProfileImg from "../../asset/image/avatar.png";

import { getHttpRequest } from "../../asset/axios";

const AuthHeader = (props) => {
  const history = useNavigate();
  const { lang } = useLanguage();

  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const pathName = window.location.pathname;

  const { _id, customername } = ENV.getUserKeys();
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  const [click, setClick] = useState(false);
  const [topHeadline, setTopHeadline] = useState([]);
  const [languages, setLanguages] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "de",
  );
  const [show, setShow] = useState(false);
  const [profileImages, setProfileImage] = useState(userProfileImg);
  const [name, setName] = useState(customername);
  const [status, setStatus] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [headerMenus, setHeaderMenus] = useState([]);
  const [cart, setCart] = useState(
    JSON.parse(_localStorage.getItem("cart"))
      ? JSON.parse(_localStorage.getItem("cart"))
      : [],
  );

  localStorage.setItem("open_time", Date());

  const handleClick = () => setClick(!click);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let timer;

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const handleTimer = () => {
    setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logout();
    }, 7200 * 1000);
  };
  const setSiteLang = (code) => {
    localStorage.setItem("language", code);
    setSelectedLanguage(code);
    props.updateLanguage({ language: code, userId: _id });
    let theArr = window.location.pathname.split("/");
    theArr.pop();
    theArr = theArr.join("/");
    theArr = `${theArr}/${code}`;
    history(theArr);
    window.location.reload();
  };

  const logout = () => {
    ENV.clearStorage();
    history(`/${lang}`);
    window.location.reload();
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleTimer();
      });
    });
  }, []);

  useEffect(() => {
    props.getHeaderMenu("header");
  }, []);
  useEffect(() => {
    if (props.cms.getHeaderMenu) {
      setHeaderMenus(props.cms.headerMenus);
      props.beforeCMS();
    }
  }, [props.cms.getHeaderMenu]);

  useEffect(() => {
    const startDate = localStorage.getItem("open_time");
    const endDate = localStorage.getItem("close_time");
    const diff = Math.abs(Date.parse(startDate) - Date.parse(endDate)) / 1000;
    if (diff >= 7200) {
      logout();
    }
  }, []);

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      localStorage.setItem("close_time", Date());
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    const {
      apiUrls: { restCountries },
    } = ENV;
    getHttpRequest(restCountries)
      .then((response) => {})
      .catch();
    props.getSettings();
    props.getUser(_id);
    props.getLanguages();
    if (!props.content.getAuthHeaderContent) {
      props.getAuthHeaderContent("auth-header");
    }
  }, []);

  useEffect(() => {
    const { customername, status, profileImage } = ENV.getUserKeys();
    setName(customername);
    setStatus(status);
    setProfileImage(
      profileImage === ""
        ? userProfileImg
        : ENV.uploadedImgPath + "/" + profileImage,
    );
  }, [localStorage.getItem("attivitaEncryptedUser")]);

  useEffect(() => {
    if (props.checkout.getUser) {
      const user = props.checkout.user.data;
      if (user) {
        ENV.encryptUserData(user);
        setProfileImage(
          !user?.profileImage
            ? userProfileImg
            : ENV.uploadedImgPath + "/" + user.profileImage,
        );
        setName(user.customername);
        setStatus(user.status);
        setConfirmationCode(user.confirmationCode);
        props.beforeCheckout();
      }
    }
  }, [props.checkout.getUser]);

  useEffect(() => {
    setCart(
      JSON.parse(_localStorage.getItem("cart"))
        ? JSON.parse(_localStorage.getItem("cart"))
        : [],
    );
  }, [_localStorage.getItem("cart")]);

  useEffect(() => {
    if (props.checkout.getLanguagesAuth) {
      const { languages } = props.checkout.languages;
      setLanguages(languages);
    }
  }, [props.checkout.getLanguagesAuth]);

  useEffect(() => {
    if (props.siteSetting.getSettings) {
      setTopHeadline(props.siteSetting.settings);
    }
  }, [props.siteSetting.getSettings]);

  useEffect(() => {
    if (props.content.getAuthHeaderContent) {
      props.beforeContent();
    }
  }, [props.content.getAuthHeaderContent]);

  return (
    <Fragment>
      <div className="top-header">
        <ul>
          <li>
            <p>{lang === "de" ? topHeadline.leftDE : topHeadline.left}</p>
          </li>
          <li>
            <p>{lang === "de" ? topHeadline.middleDE : topHeadline.middle}</p>
          </li>
          <li>
            <p>{lang === "de" ? topHeadline.rightDE : topHeadline.right}</p>
          </li>
        </ul>
      </div>
      <header id="header" className="header">
        <nav className="navbar header-navbar navbar-expand-lg navbar-light">
          <Container fluid>
            <strong className="logo">
              <Link to={`/${lang}`}>
                <img
                  src={logoImg}
                  className="img-fluid"
                  alt="main-logo"
                  title="logo"
                />
              </Link>
            </strong>
            <div className={click ? "nav-options active" : "nav-options"}>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    to={`/${lang}`}
                    className={`nav-link ${pathName === `/${lang}` ? "active" : ""} `}
                  >
                    {translations["Home"]}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/shop/${lang}`}
                    className={`nav-link ${pathName === `/shop/${lang}` ? "active" : ""} `}
                  >
                    {translations["Shop"]}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/about-us/${lang}`}
                    className={`nav-link ${pathName === `/about-us/${lang}` ? "active" : ""} `}
                  >
                    {translations["About Us"]}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/contact-us/${lang}`}
                    className={`nav-link ${pathName === `/contact-us/${lang}` ? "active" : ""} `}
                  >
                    {translations["Contact Us"]}
                  </Link>
                </li>
                {headerMenus &&
                  headerMenus.length > 0 &&
                  headerMenus.map((menu, index) => (
                    <li className="nav-item" key={index}>
                      <Link
                        className="nav-link"
                        target="_blank"
                        to={`/page/${menu.slug}/${lang}`}
                      >
                        {lang === "de" ? menu.germanTitle : menu.title}
                      </Link>
                    </li>
                  ))}
                <div className="my-account hide-my-account">
                  <div className="my-account-filter">
                    <div id="country" className="d-flex align-items-center">
                      <Dropdown>
                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                          <div className="country-selection-img">
                            <img
                              src={`/${selectedLanguage}.svg`}
                              className="img-fluid"
                              alt="country"
                              title=""
                            />
                            <span>{selectedLanguage}</span>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <ul>
                            {languages &&
                              languages.map((lang, index) => (
                                <li key={index}>
                                  <a className="dropdown-item" href="#">
                                    <Dropdown.Item>
                                      <span
                                        id="lang-name"
                                        title={lang.name}
                                        onClick={() =>
                                          setSiteLang(lang.iso_code)
                                        }
                                      >
                                        {lang.name}
                                      </span>
                                    </Dropdown.Item>
                                    <span id="flag-img">
                                      <img
                                        src={`/${lang.iso_code}.svg`}
                                        className="img-fluid"
                                        alt="country"
                                        title=""
                                      />
                                    </span>
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="user-profile-wrapper">
                    <Dropdown>
                      <Dropdown.Toggle variant="default" id="dropdown-basic">
                        <div className="my-account-login profile-img">
                          <div className="account-text-btn">
                            <img
                              src={
                                profileImages ? profileImages : userProfileImg
                              }
                              alt=""
                              className="img-fluid"
                              title="Profile mmmmm"
                            />
                            <span>{name}</span>
                          </div>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <ul className="list-unstyled">
                          <li className="user-header">
                            <img
                              src={
                                profileImages ? profileImages : userProfileImg
                              }
                              alt=""
                              className="img-fluid"
                              title="Profile ffffff"
                            />
                            <p className="mt-2">
                              <span className="text-purple font-weight-bold text-capitalize">
                                {name}
                              </span>{" "}
                              <br />
                            </p>
                            <span className="badge unverified-badge">
                              {confirmationCode
                                ? translations["Email Verified"]
                                : translations["Email Not Verified"]}
                            </span>
                            <p className="mt-3 notification-error-msg">
                              <span className="text-danger">
                                {status
                                  ? ""
                                  : translations[
                                      "Your account is not active. Kindly contact admin to verify your account."
                                    ]}
                              </span>
                            </p>
                          </li>
                          <li className="user-body">
                            <Row>
                              <Col lg="4" md="4" sm="4" className="text-center">
                                <Link to={`/dashboard${lang}`}>
                                  {translations["Account"]}
                                </Link>
                              </Col>
                              <Col lg="4" md="4" sm="4" className="text-center">
                                <Link to={`/user-profile${lang}`}>
                                  {translations["Profile"]}
                                </Link>
                              </Col>
                              <Col
                                lg="4"
                                md="4"
                                sm="4"
                                className="text-center"
                                onClick={() => logout()}
                              >
                                <Link>{translations["Logout"]}</Link>
                              </Col>
                            </Row>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </ul>
            </div>
            <div className="my-account show-my-account">
              <div
                className="cart-icon-holder position-relative"
                onClick={() => handleShow()}
              >
                <FontAwesomeIcon icon={faBagShopping} title="Cart" />
                <span className="cart-qty count my-count">{cart.length}</span>
              </div>
              <div className="my-account-filter">
                <div id="country" className="d-flex align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="default" id="dropdown-basic">
                      <div className="country-selection-img">
                        <img
                          src={`/${selectedLanguage}.svg`}
                          className="img-fluid"
                          alt="country"
                          title=""
                        />
                        <span>{selectedLanguage}</span>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <ul>
                        {languages &&
                          languages.map((lang, index) => (
                            <li key={index}>
                              <a className="dropdown-item" href="#">
                                <span
                                  id="lang-name"
                                  title={lang.name}
                                  onClick={() => setSiteLang(lang.iso_code)}
                                >
                                  {lang.name}
                                </span>
                                <span id="flag-img">
                                  <img
                                    src={`/${lang.iso_code}.svg`}
                                    className="img-fluid"
                                    alt="country"
                                    title=""
                                  />
                                </span>
                              </a>
                            </li>
                          ))}
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="user-profile-wrapper">
                <Dropdown>
                  <Dropdown.Toggle variant="default" id="dropdown-basic">
                    <div className="my-account-login profile-img">
                      <div className="account-text-btn">
                        <img
                          src={profileImages ? profileImages : userProfileImg}
                          alt=""
                          className="img-fluid"
                          title="Profile"
                        />
                        <span>{name}</span>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <ul className="list-unstyled">
                      <li className="user-header">
                        <img
                          src={profileImages ? profileImages : userProfileImg}
                          alt=""
                          className="img-fluid"
                          title="Profile"
                        />
                        <p className="mt-2">
                          <span className="text-purple font-weight-bold text-capitalize">
                            {name}
                          </span>{" "}
                          <br />
                        </p>
                        <span className="badge unverified-badge">
                          {confirmationCode
                            ? translations["Email Verified"]
                            : translations["Email Not Verified"]}
                        </span>
                        <p className="mt-3 notification-error-msg">
                          <span className="text-danger">
                            {status
                              ? ""
                              : translations[
                                  "Your account is not active. Kindly contact admin to verify your account."
                                ]}
                          </span>
                        </p>
                      </li>
                      <li className="user-body">
                        <Row>
                          <Col md="4" className="text-center">
                            <Link to={`/dashboard/${lang}`}>
                              {translations["Account"]}
                            </Link>
                          </Col>
                          <Col md="4" className="text-center">
                            <Link to={`/user-profile/${lang}`}>
                              {translations["Profile"]}
                            </Link>
                          </Col>
                          <Col
                            md="4"
                            className="text-center"
                            onClick={() => logout()}
                          >
                            <Link>{translations["Logout"]}</Link>
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Container>
        </nav>
        <div className="login-menu-m">
          <div
            className="cart-icon-holder position-relative"
            onClick={() => handleShow()}
          >
            <FontAwesomeIcon
              icon={faBagShopping}
              onClick={() => handleShow()}
              title="Cart"
            />
            <span className="cart-qty count my-count">{cart.length}</span>
          </div>
          <div className="mobile-menu" onClick={handleClick}>
            {click ? (
              <img src={closeMenuImg} className="menu-icon" alt="" />
            ) : (
              <img src={menuIcon} className="menu-icon" alt="" />
            )}
          </div>
        </div>
      </header>
      <Modal show={show} onHide={handleClose} className="cart-modal">
        <Modal.Header closeButton>
          <h4 className="mb-0">
            <span className="glyphicon glyphicon-shopping-cart"></span>{" "}
            <b>{translations["Cart"]}</b>
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Cart />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  siteSetting: state.siteSetting,
  checkout: state.checkout,
  content: state.content,
  profile: state.profile,
  cms: state.cms,
});
export default connect(mapStateToProps, {
  beforeSettings,
  getSettings,
  beforeCheckout,
  getUser,
  getLanguages,
  getAuthHeaderContent,
  beforeContent,
  getMenu,
  beforeCMS,
  getHeaderMenu,
  updateLanguage,
})(AuthHeader);
