import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LocalizedStrings from "react-localization";
import swal from "sweetalert";
import TopHeader from "../header/topHeader";
import Footer from "../footer/Footer";
import { ENV } from "../../config/config";
import localizations from "../../localization";
import { handleResponse } from "../../util/helper";
import "./Dashboard.css";
import "rc-pagination/assets/index.css";
import { getHttpRequest } from "../../asset/axios";

const VendorConfiguration = (props) => {
  const history = useNavigate();
  const userData = ENV.getUserKeys();

  const [products, setProducts] = useState([]);
  const [extraData, setExtraData] = useState([]);
  const [showFiled, setShowFiled] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [lang, setLang] = useState(localStorage.getItem("language") ?? "de");
  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);
  useEffect(() => {
    fetchProducts();
  }, []);
  useEffect(() => {
    if (
      localStorage.getItem("language") === "en" ||
      localStorage.getItem("language") === "de"
    ) {
      translations.setLanguage(localStorage.getItem("language"));
      setLang(localStorage.getItem("language"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("language")]);

  const fetchProducts = () => {
    const url = `${ENV.url.replace("front", "vendor")}product/get`;
    getHttpRequest(url)
      .then(handleResponse)
      .then((data) => {
        if (data.success) {
          setProducts(data.products);
          setExtraData(
            data.products?.map((elem) => ({
              _id: elem._id,
              manufacturerName: elem?.manufacturer?.name,
              manufacturerImage: elem?.manufacturer?.image,
              name: elem?.name,
              salesPrice: elem?.salesPrice,
              status: elem?.status,
              vendorExtraPrice: elem?.vendorProduct
                ? elem?.vendorProduct.vendorExtraPrice
                : 0,
              extraPrice: elem?.vendorProduct
                ? elem?.vendorProduct.extraPrice
                : 0,
              priceType: elem?.vendorProduct?.priceType,
            })),
          );
        } else {
        }
      })
      .catch((errors) => {});
  };
  const updateProductPrice = (body) => {
    const url = `${ENV.url.replace("front", "vendor")}product/update-product-price`;
    fetch(url, {
      method: "PUT",
      headers: {
        Authorization: ENV.Authorization,
        vendorToken: userData.vendorToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((data) => {
        fetchProducts();
      })
      .catch((errors) => {});
  };

  const onUpdateProduct = (price, id) => {
    setShowFiled(id);
    setExtraData((pre) =>
      pre.map((elem) => {
        if (elem?._id === id) {
          if (products) {
            return {
              ...elem,
              vendorExtraPrice: price,
            };
          }
        }
        return elem;
      }),
    );
  };

  const updateExtraPrice = async (price, id) => {
    const body = {
      extraPrice: price,
      productId: id,
    };
    await updateProductPrice(body);
  };

  const checkAmountType = (product) => {
    //1=Fixed 0=percentage
    if (product.priceType === 1) {
      return product?.extraPrice
        ? parseFloat(product.salesPrice) + parseFloat(product?.extraPrice ?? 0)
        : parseFloat(product.salesPrice);
    } else if (product.priceType === 0) {
      if (product.extraPrice) {
        const calculatePercentage =
          (product.extraPrice / 100) * parseFloat(product.salesPrice);
        const totalAmount =
          parseFloat(product.salesPrice) + parseFloat(calculatePercentage);
        return parseFloat(totalAmount).toFixed(2);
      }
      return parseFloat(product.salesPrice).toFixed(2);
    } else {
      return product?.extraPrice
        ? parseFloat(product.salesPrice) + parseFloat(product?.extraPrice)
        : parseFloat(product.salesPrice);
    }
  };

  const calculateVendorExtraPrice = (product) => {
    let value = 0;
    if (product.priceType === 1) {
      value = product?.vendorExtraPrice
        ? parseFloat(product.salesPrice) +
          parseFloat(product?.extraPrice) +
          parseFloat(product?.vendorExtraPrice)
        : parseFloat(product.salesPrice) + parseFloat(product?.extraPrice);
    } else if (product.priceType === 0) {
      const calculatePercentage =
        (product.extraPrice / 100) * parseFloat(product.salesPrice);
      let totalAmount =
        parseFloat(product.salesPrice) + parseFloat(calculatePercentage);
      totalAmount = totalAmount.toFixed(2);
      const finalAmount = product?.vendorExtraPrice
        ? parseFloat(totalAmount) + parseFloat(product?.vendorExtraPrice)
        : parseFloat(totalAmount);
      value = parseFloat(finalAmount).toFixed(2);
    } else {
      value = product?.vendorExtraPrice
        ? parseFloat(product.salesPrice) +
          parseFloat(product?.extraPrice) +
          parseFloat(product?.vendorExtraPrice)
        : parseFloat(product.salesPrice) + parseFloat(product?.extraPrice);
    }
    try {
      value = value.toFixed(2);
    } catch (e) {
      value = parseFloat(value).toFixed(2);
    }
    return value;
  };
  const copyToken = async (text) => {
    navigator.clipboard.writeText(userData.vendorToken);
    swal({
      text: translations["Copied"],
      icon: "success",
      button: "OK",
    });
  };
  return (
    <div>
      <TopHeader />
      <div id="main">
        <div
          className="dark-green div-breadcrumbs"
          style={{
            background: "rgb(146 132 250)",
            color: "white",
            padding: "10px",
          }}
        >
          <div className="container">
            <div>
              <Link
                style={{ color: "white", "font-weight": "500" }}
                to={`/dashboard/${lang}`}
              >
                {translations["Dashboard"]}
              </Link>{" "}
              /{translations["API Configuration"]}
            </div>
          </div>
        </div>
        <section className="content-section mt-3 mb-3" id="account-page">
          <Container>
            <Row className="mt-2 bottom-space">
              <Col lg="12" sm="12">
                <Row className="mb-4">
                  <Col md={6}>
                    <h3 className="mt-3 mb-3 w-100">
                      {translations["Extra Price Configuration"]}
                    </h3>
                  </Col>
                  <Col
                    md={6}
                    onClick={() => {
                      copyToken();
                    }}
                  >
                    <Row>
                      <Col md={4} className="mt-3 mb-3">
                        API Key:
                      </Col>
                      <Col md={8}>
                        <input
                          className="form-control mt-3 mb-3 w-100"
                          onClick={() => {
                            copyToken();
                          }}
                          type="text"
                          value={userData.vendorToken}
                          readOnly={"readOnly"}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col md={6}>
                    <input
                      placeholder={translations["Search"]}
                      className="form-control"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Col>

                  <Col md={6}>
                    <a
                      href="https://documentation.attivita.de/"
                      target="_blank"
                      className="btn btn-info float-right"
                    >
                      {" "}
                      View API Docs
                    </a>
                  </Col>
                </Row>
                <div
                  id="sales-datatable_wrapper"
                  className="dataTables_wrapper dt-bootstrap4 no-footer w-100"
                >
                  <div className="table-responsive">
                    <table
                      id="sales-datatable"
                      className="table table-bordered table-striped dataTable no-footer dtr-inline"
                    >
                      <thead>
                        <tr role="row">
                          <th className="text-center serial-col">#</th>
                          <th className="text-center td-actions">
                            {translations["Product Name"]}
                          </th>
                          <th className="text-center td-actions">
                            {translations["Product Price"]}
                          </th>
                          <th className="text-center td-actions">
                            {translations["Product Extra Price"]}
                          </th>
                          <th className="text-center td-actions">
                            {translations["Total Cost"]}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {extraData && extraData.length ? (
                          extraData.map((item, index) => {
                            if (
                              !searchQuery ||
                              item.name
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                              return (
                                <tr key={index}>
                                  <td className="text-center serial-col">
                                    {index + 1}
                                  </td>
                                  <td className="text-center td-actions">
                                    {item.name}
                                  </td>
                                  <td className="text-center td-actions">
                                    {checkAmountType(item)}
                                  </td>
                                  <td className="text-center td-actions">
                                    <Form.Control
                                      type="number"
                                      value={item?.vendorExtraPrice}
                                      name="vendorExtraPrice"
                                      onChange={(e) => {
                                        onUpdateProduct(
                                          e.target.value,
                                          item?._id,
                                        );
                                      }}
                                      placeholder="Enter extra price"
                                    />
                                    {showFiled === item?._id && (
                                      <Button
                                        className="btn-fill pull-right mt-3 float-right"
                                        type="submit"
                                        variant="info"
                                        onClick={() =>
                                          updateExtraPrice(
                                            item?.vendorExtraPrice,
                                            item?._id,
                                          )
                                        }
                                      >
                                        Update
                                      </Button>
                                    )}
                                  </td>
                                  <td className="text-center td-actions">
                                    {calculateVendorExtraPrice(item)}
                                  </td>
                                </tr>
                              );
                          })
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              <div className="alert alert-info" role="alert">
                                No Product Found
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default VendorConfiguration;
