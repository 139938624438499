import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import LocalizedStrings from "react-localization";
import moment from "moment";
import TopHeader from "../header/topHeader";
import Footer from "../footer/Footer";
import { beforeDashboard, getOrder } from "../dashboard/dashboard.action";
import { currencyFormat } from "../../util/function";
import localizations from "../../localization";
import { useLanguage } from "../../context/language";
import "./QuotationDetail.css";

const QuotationDetail = (props) => {
  const history = useNavigate();
  const { lang } = useLanguage();

  const translations = new LocalizedStrings(localizations, {
    logsEnabled: false,
  });
  translations.setLanguage(lang);

  const [order, setOrder] = useState(null);

  useEffect(() => {
    props.getOrder(window.location.pathname.split("/")[2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.dashboard.getOrderAuth) {
      setOrder(props.dashboard.data.order);
      props.beforeDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboard.getOrderAuth]);

  return (
    <Fragment>
      <TopHeader />
      <div
        className="dark-green div-breadcrumbs"
        style={{
          background: "rgb(146 132 250)",
          color: "white",
          padding: "10px",
        }}
      >
        <div className="container">
          <div>
            <Link
              style={{ color: "white", "font-weight": "500" }}
              to={`/dashboard/${lang}`}
            >
              {translations["Dashboard"]}
            </Link>{" "}
            /{translations["Order Detail"]} {order && order.order_nr}
          </div>
        </div>
      </div>
      <div id="main" className="custom-w-wrapper">
        {order && (
          <section className="vouchers-main voucher-table custom-container mb-5">
            <Container fluid>
              <Row>
                <Col lg="12" md="12" xs="12">
                  <div className="invoice-left-wrapper">
                    <Row>
                      <Col lg="6" md="6" xs="12">
                        <span className="currency-holder">
                          {currencyFormat(order.grandTotal)}
                        </span>
                      </Col>
                      <Col lg="6" md="6" xs="12">
                        <span className="text-capitalize pricing-holder">
                          {translations["Sale order pricing"]}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg="12" md="12" xs="12">
                  <div className="invoice-header">
                    <h4>
                      {translations["Sales Orders"]}/{order.order_nr}
                    </h4>
                  </div>
                  <div className="invoice-conatienr">
                    <div className="info-wrapper mb-3">
                      <p className="mb-2">
                        <b>{translations["Order Date"]}:</b>{" "}
                        {moment(order.createdAt).format("MM-DD-YYYY")}
                      </p>
                      <h3>{translations["Invoicing and Shipping Address"]}:</h3>
                      <p>{order.shipping.address}</p>
                    </div>
                    {order.isInvoiced === true && (
                      <div className="invoice-detail">
                        <h6 className="font-weight-bold">
                          {translations["Invoice"]}
                        </h6>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            history(`/invoice-detail/${order._id}/${lang}`)
                          }
                        >
                          {order.invoice_nr} /{" "}
                          {moment(order.invoicedAt).format("MM-DD-YYYY")}
                        </span>
                      </div>
                    )}
                    <h5>{translations["Pricing"]}</h5>
                  </div>
                  <div className="table-responsive">
                    <table className="invoice-detail-table table table-striped attivita-table">
                      <thead>
                        <tr className="data-table-header">
                          <th scope="col">
                            <div className="data-table-header-seperator">
                              {translations["Products"]}
                            </div>
                          </th>
                          <th scope="col">
                            <div className="data-table-header-seperator">
                              {translations["Quantity"]}
                            </div>
                          </th>
                          <th scope="col">
                            <div className="data-table-header-seperator">
                              {translations["Unit Price"]}
                            </div>
                          </th>
                          <th scope="col">
                            <div className="data-table-header-seperator">
                              {translations["Discount"]}
                            </div>
                          </th>
                          <th scope="col">
                            <div className="data-table-header-seperator">
                              {translations["Amount"]}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.items.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.item}</td>
                              <td>{item.quantity}</td>
                              <td>{currencyFormat(item.salesPrice)}</td>
                              <td>
                                {item.discountType === 0
                                  ? `${item.discountPercentage} %`
                                  : item.discountType === 1
                                    ? ` ${currencyFormat(item.discountPercentage)}`
                                    : ""}
                              </td>
                              <td>{currencyFormat(item.subTotal)}</td>
                            </tr>
                          );
                        })}
                        <tr className=" bg-transparent">
                          <td colspan="3" className="text-right custom-bg"></td>
                          <td
                            colspan=""
                            className="text-right order-left-formate"
                          >
                            <b>{translations["Sub Total"]}</b>
                          </td>
                          <td className="text-left order-right-formate">
                            {currencyFormat(order.subtotal)}
                          </td>
                        </tr>
                        <tr className=" bg-transparent">
                          <td colspan="3" className="text-right custom-bg"></td>
                          <td
                            colspan=""
                            className="text-right order-left-formate"
                          >
                            <b>{translations["Discount"]}</b>
                          </td>
                          <td className="text-left order-right-formate">
                            {currencyFormat(order.discountTotal)}
                          </td>
                        </tr>
                        <tr className=" bg-transparent">
                          <td colspan="3" className="text-right custom-bg"></td>
                          <td
                            colspan=""
                            className="text-right order-left-formate"
                          >
                            <b>
                              {order.vatLabel} ({order.vatPercentage}%)
                            </b>
                          </td>
                          <td className="text-left order-right-formate">
                            {currencyFormat(order.taxtTotal)}
                          </td>
                        </tr>
                        <tr className=" bg-transparent">
                          <td colspan="3" className="text-right custom-bg"></td>
                          <td
                            colspan=""
                            className="text-right order-left-formate"
                          >
                            <b>{translations["Total"]}</b>
                          </td>
                          <td className="text-left order-right-formate">
                            {currencyFormat(order.grandTotal)}
                          </td>
                        </tr>
                        {order.paidAmount > 0 && (
                          <tr className=" bg-transparent">
                            <td
                              colspan="3"
                              className="text-right custom-bg"
                            ></td>
                            <td
                              colspan=""
                              className="text-right order-left-formate"
                            >
                              <b>{translations["Paid"]}</b>
                            </td>
                            <td className="text-left order-right-formate">
                              {currencyFormat(order.paidAmount)}
                            </td>
                          </tr>
                        )}
                        {order.refundedAmount > 0 && (
                          <tr className=" bg-transparent">
                            <td
                              colspan="3"
                              className="text-right custom-bg"
                            ></td>
                            <td
                              colspan=""
                              className="text-right order-left-formate"
                            >
                              <b>{translations["Refunded"]}</b>
                            </td>
                            <td className="text-left order-right-formate">
                              {currencyFormat(order.refundedAmount)}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}
      </div>
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, { beforeDashboard, getOrder })(
  QuotationDetail,
);
